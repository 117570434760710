<template>
  <el-dialog
    v-drag
    :title="!type ? '支付账单明细' : '未支付账单'"
    :visible.sync="dialogVisible"
    width="800px"
    :show-close="true"
    :append-to-body="true"
    :close-on-click-modal="false"
    :close-on-press-escape="false"
    :before-close="cancel"
  >
    <div class="content">
      <BaseTable
        height="360"
        :tableData="tableData"
        :isPager="false"
      >
      </BaseTable>
    </div>
  </el-dialog>
</template>
<script>
const BaseTable = () => import('@/components/table/BaseTable')
import { getDefrayPaymentDetail, getNotReviewDefrayInvoice } from '@/api/fee'
export default {
  components: { BaseTable },
  props: {
    dialogVisible: {
      type: Boolean,
      default: false
    },
    selectionItem: {
      type: Object,
      default: () => {}
    },
    type: {
      type: Number,
      default: 0
    },
    rowData: {
      type: Object,
      default: () => {}
    }
  },
  data() {
    return {
      tableData: {
        fileds: [
          {
            filed: 'invoiceNumber',
            label: '发票号码',
            width: '188'
          },
          {
            filed: 'blNo',
            label: '提单号码',
            width: '188'
          },
          {
            filed: 'amount',
            label: '金额',
            width: '188'
          },
          {
            filed: 'currency',
            label: '币制',
            width: '188'
          }
        ],
        columns: []
      }
    }
  },
  watch: {
    dialogVisible(val) {
      val && this.getList()
    }
  },
  methods: {
    async getList() {
      try {
        let res
        !this.type && (res = await getDefrayPaymentDetail({ defrayPaymentId: this.rowData.id }))
        this.type && (res = await getNotReviewDefrayInvoice([this.rowData.id]))
        if (res.success) {
          !this.type && (this.tableData.columns = res.data)
          this.type && (this.tableData.columns = res.data.defrayInvoices)
        } else {
          this.$message.error(res.errorMessage)
        }
      } catch (error) {
        console.error(error)
      }
    },
    cancel() {
      this.$emit('cancel')
    }
  }
}
</script>
<style lang="less" scoped>
.content {
  width: 100%;
}
</style>
