var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "el-dialog",
    {
      directives: [{ name: "drag", rawName: "v-drag" }],
      attrs: {
        title: "财务审批",
        visible: _vm.dialogVisible,
        width: "800px",
        "close-on-click-modal": false,
        "close-on-press-escape": false,
        "before-close": _vm.cancel,
        top: "2%",
      },
      on: {
        "update:visible": function ($event) {
          _vm.dialogVisible = $event
        },
      },
    },
    [
      _c(
        "div",
        { staticClass: "content" },
        [
          _c(
            "el-form",
            {
              ref: "form",
              staticClass: "form",
              attrs: {
                model: _vm.form,
                rules: _vm.rules,
                "label-position": "top",
              },
            },
            [
              _c(
                "el-row",
                [
                  _c(
                    "el-col",
                    { attrs: { span: 24 } },
                    [
                      _c(
                        "el-form-item",
                        {
                          attrs: { prop: "reviewComments", label: "审批意见" },
                        },
                        [
                          _c("el-input", {
                            attrs: {
                              type: "textarea",
                              resize: "none",
                              placeholder: "请输入",
                              autosize: { minRows: 1, maxRows: 3 },
                            },
                            model: {
                              value: _vm.form.reviewComments,
                              callback: function ($$v) {
                                _vm.$set(_vm.form, "reviewComments", $$v)
                              },
                              expression: "form.reviewComments",
                            },
                          }),
                        ],
                        1
                      ),
                    ],
                    1
                  ),
                ],
                1
              ),
              _c(
                "el-row",
                [
                  _c(
                    "el-col",
                    { attrs: { span: 12 } },
                    [
                      _c(
                        "el-form-item",
                        {
                          staticStyle: { width: "320px" },
                          attrs: { prop: "totalAmount", label: "支付金额" },
                        },
                        [
                          _c(
                            "el-input",
                            {
                              staticStyle: { width: "100%" },
                              attrs: {
                                placeholder: "请输入",
                                oninput: "value=value.replace(/[^0-9.]/g,'')",
                              },
                              model: {
                                value: _vm.form.totalAmount,
                                callback: function ($$v) {
                                  _vm.$set(_vm.form, "totalAmount", $$v)
                                },
                                expression: "form.totalAmount",
                              },
                            },
                            [
                              _c(
                                "span",
                                {
                                  staticClass: "suffix",
                                  attrs: { slot: "suffix" },
                                  slot: "suffix",
                                },
                                [_vm._v(_vm._s(_vm.currency))]
                              ),
                            ]
                          ),
                        ],
                        1
                      ),
                    ],
                    1
                  ),
                  _c(
                    "el-col",
                    { attrs: { span: 12 } },
                    [
                      _c(
                        "el-form-item",
                        {
                          staticStyle: { width: "320px" },
                          attrs: { prop: "paymentTime", label: "支付日期" },
                        },
                        [
                          _c("el-date-picker", {
                            staticStyle: { width: "100%" },
                            attrs: { type: "date", placeholder: "请选择日期" },
                            model: {
                              value: _vm.form.paymentTime,
                              callback: function ($$v) {
                                _vm.$set(_vm.form, "paymentTime", $$v)
                              },
                              expression: "form.paymentTime",
                            },
                          }),
                        ],
                        1
                      ),
                    ],
                    1
                  ),
                ],
                1
              ),
              _c(
                "el-row",
                [
                  _c(
                    "el-col",
                    { attrs: { span: 12 } },
                    [
                      _c(
                        "el-form-item",
                        {
                          staticStyle: { width: "320px" },
                          attrs: { label: "供应商" },
                        },
                        [
                          _c("el-input", {
                            attrs: { disabled: "" },
                            model: {
                              value: _vm.rowData.supplierName,
                              callback: function ($$v) {
                                _vm.$set(_vm.rowData, "supplierName", $$v)
                              },
                              expression: "rowData.supplierName",
                            },
                          }),
                        ],
                        1
                      ),
                    ],
                    1
                  ),
                  _c(
                    "el-col",
                    { attrs: { span: 12 } },
                    [
                      _c(
                        "el-form-item",
                        {
                          staticStyle: { width: "320px" },
                          attrs: { prop: "fileList", label: "上传支付水单" },
                        },
                        [
                          _c(
                            "el-upload",
                            {
                              staticClass: "upload-demo",
                              attrs: {
                                action: "#",
                                "show-file-list": false,
                                "http-request": _vm.httpRequest,
                              },
                            },
                            [
                              _c("div", { staticClass: "upload flex_c_c" }, [
                                _c("img", {
                                  attrs: {
                                    src: require("@/assets/images/bill/upload-1.png"),
                                  },
                                }),
                                _vm._v(" 选择文件上传 "),
                              ]),
                              _c(
                                "div",
                                {
                                  staticClass: "fileList",
                                  style: {
                                    display:
                                      _vm.form.fileList[0] &&
                                      _vm.form.fileList[0].fileName
                                        ? "flex"
                                        : "none",
                                  },
                                  attrs: { slot: "tip" },
                                  slot: "tip",
                                },
                                [
                                  _c(
                                    "div",
                                    {
                                      staticStyle: {
                                        flex: "1",
                                        display: "flex",
                                        "align-items": "center",
                                      },
                                    },
                                    [
                                      _c(
                                        "div",
                                        { staticClass: "flex_c_c" },
                                        [
                                          _c("el-progress", {
                                            attrs: {
                                              type: "circle",
                                              width: 40,
                                              height: 40,
                                              "stroke-width": 4,
                                              percentage: _vm.form.fileList
                                                .length
                                                ? 100
                                                : 0,
                                            },
                                          }),
                                        ],
                                        1
                                      ),
                                      _c(
                                        "div",
                                        { staticClass: "file" },
                                        [
                                          _c(
                                            "el-tooltip",
                                            {
                                              ref: "tlp",
                                              attrs: {
                                                content:
                                                  _vm.form.fileList[0]
                                                    ?.fileName,
                                                effect: "dark",
                                                disabled: !_vm.tooltipFlag,
                                                placement: "bottom",
                                              },
                                            },
                                            [
                                              _c(
                                                "p",
                                                {
                                                  staticClass: "fileName",
                                                  on: {
                                                    mouseenter: function (
                                                      $event
                                                    ) {
                                                      return _vm.visibilityChange(
                                                        $event
                                                      )
                                                    },
                                                  },
                                                },
                                                [
                                                  _vm._v(
                                                    " " +
                                                      _vm._s(
                                                        _vm.form.fileList[0]
                                                          ?.fileName
                                                      ) +
                                                      " "
                                                  ),
                                                ]
                                              ),
                                            ]
                                          ),
                                          _c("p", { staticClass: "fileSize" }, [
                                            _vm._v(
                                              " " +
                                                _vm._s(
                                                  _vm.form.fileList[0]?.fileSize
                                                ) +
                                                " mb "
                                            ),
                                          ]),
                                        ],
                                        1
                                      ),
                                    ]
                                  ),
                                  _c("i", {
                                    staticClass: "el-icon-delete icon",
                                    on: { click: _vm.deleteFile },
                                  }),
                                ]
                              ),
                            ]
                          ),
                        ],
                        1
                      ),
                    ],
                    1
                  ),
                ],
                1
              ),
              _c(
                "el-row",
                [
                  _c(
                    "el-col",
                    { attrs: { span: 12 } },
                    [
                      _c(
                        "el-form-item",
                        {
                          staticStyle: { width: "320px" },
                          attrs: { label: "收款账号" },
                        },
                        [
                          _c("div", { staticClass: "custom_supplier" }, [
                            _c("div", { staticClass: "account_box" }, [
                              _c("div", { staticClass: "account-t" }, [
                                _c(
                                  "span",
                                  {
                                    class: [
                                      "account_mark",
                                      _vm.accountMark(
                                        _vm.bankAccountInfo.bankName
                                      ),
                                    ],
                                  },
                                  [_vm._v(_vm._s(_vm.bankAccountInfo.bankName))]
                                ),
                                _c("span", { staticClass: "account_no" }, [
                                  _vm._v(
                                    _vm._s(_vm.bankAccountInfo.accountNumber)
                                  ),
                                ]),
                              ]),
                              _c("div", { staticClass: "account-b" }, [
                                _vm._v(_vm._s(_vm.bankAccountInfo.accountName)),
                              ]),
                            ]),
                          ]),
                        ]
                      ),
                    ],
                    1
                  ),
                  _c(
                    "el-col",
                    { attrs: { span: 12 } },
                    [
                      _c(
                        "el-form-item",
                        {
                          staticStyle: { width: "320px" },
                          attrs: { label: "支付方式", prop: "payableType" },
                        },
                        [
                          _c(
                            "el-select",
                            {
                              staticStyle: { width: "100%" },
                              attrs: { placeholder: "请选择" },
                              model: {
                                value: _vm.form.payableType,
                                callback: function ($$v) {
                                  _vm.$set(_vm.form, "payableType", $$v)
                                },
                                expression: "form.payableType",
                              },
                            },
                            _vm._l(
                              _vm.config.payableTypeOptions,
                              function (item) {
                                return _c("el-option", {
                                  key: item.value,
                                  attrs: {
                                    label: item.label,
                                    value: item.value,
                                  },
                                })
                              }
                            ),
                            1
                          ),
                        ],
                        1
                      ),
                    ],
                    1
                  ),
                ],
                1
              ),
              _c(
                "el-row",
                [
                  _c("div", { staticClass: "form-item" }, [
                    _c(
                      "label",
                      { staticClass: "lable" },
                      [
                        _c("div", { staticClass: "lable-title" }, [
                          _c("span", { staticClass: "red" }, [_vm._v("* ")]),
                          _vm._v("选择账单 "),
                        ]),
                        _c(
                          "el-input",
                          {
                            staticClass: "search",
                            staticStyle: { width: "200px" },
                            attrs: {
                              size: "small",
                              placeholder: "请输入提单号搜索",
                            },
                            model: {
                              value: _vm.search,
                              callback: function ($$v) {
                                _vm.search = $$v
                              },
                              expression: "search",
                            },
                          },
                          [
                            _c("i", {
                              staticClass: "el-input__icon el-icon-search",
                              attrs: { slot: "suffix" },
                              slot: "suffix",
                            }),
                          ]
                        ),
                      ],
                      1
                    ),
                  ]),
                  _c(
                    "el-form-item",
                    { attrs: { prop: "invoiceIds" } },
                    [
                      _c("BaseTable", {
                        attrs: {
                          height: 220,
                          tableData: {
                            ..._vm.tableData,
                            columns: _vm.columns,
                            mutiCheck: true,
                          },
                          isPager: false,
                          border: true,
                        },
                        on: {
                          handleSelectionChange: _vm.handleSelectionChange,
                        },
                      }),
                    ],
                    1
                  ),
                ],
                1
              ),
            ],
            1
          ),
        ],
        1
      ),
      _c(
        "span",
        { staticClass: "footers", attrs: { slot: "footer" }, slot: "footer" },
        [
          _c(
            "div",
            { staticClass: "cancel flex_c_c", on: { click: _vm.cancel } },
            [_vm._v("取 消")]
          ),
          _c(
            "div",
            {
              staticClass: "ok flex_c_c",
              on: {
                click: function ($event) {
                  return _vm.ok("form")
                },
              },
            },
            [_vm._v("确 定")]
          ),
        ]
      ),
    ]
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }