var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "div",
    [
      _c(
        "Drawer",
        {
          attrs: { drawer: _vm.drawer, withHeader: false, size: 600 },
          on: { close: _vm.close },
        },
        [
          _c(
            "div",
            {
              directives: [
                {
                  name: "loading",
                  rawName: "v-loading",
                  value: _vm.loading,
                  expression: "loading",
                },
              ],
              staticClass: "view",
            },
            [
              _c("div", { staticClass: "header" }, [
                _c("div", { staticClass: "left" }, [
                  _c("span", { staticClass: "title" }, [_vm._v("审批")]),
                  _c("p", { staticClass: "claim-no" }, [
                    _vm._v("申付单号：" + _vm._s(_vm.rowData.defrayNumber)),
                  ]),
                ]),
                _c("i", {
                  staticClass: "el-icon-close icon",
                  on: { click: _vm.close },
                }),
              ]),
              _c(
                "div",
                { staticClass: "progress" },
                [
                  _vm._l(_vm.detailList, function (item, $index) {
                    return _c("div", { key: item.id, staticClass: "panel" }, [
                      _c("div", { staticClass: "point-content" }, [
                        $index
                          ? _c("div", { staticClass: "top-line acitve" })
                          : _vm._e(),
                        _c("div", {
                          class: [
                            "point",
                            { acitve: $index <= _vm.detailList.length },
                          ],
                        }),
                        $index + 1 < _vm.detailList.length ||
                        _vm.rowData.status !== 25
                          ? _c("div", {
                              staticClass: "bottom-line",
                              class: {
                                acitve:
                                  $index + 1 < _vm.detailList.length ||
                                  _vm.rowData.isReviewPermission,
                              },
                            })
                          : _vm._e(),
                      ]),
                      _c(
                        "div",
                        { staticClass: "content" },
                        [
                          $index < _vm.detailList.length &&
                          item.isApproved &&
                          !item.isUnfold &&
                          item.reviewType !== 20
                            ? _c("img", {
                                staticClass: "approved",
                                attrs: {
                                  src: require("@/assets/images/const/isApproved.png"),
                                },
                              })
                            : $index < _vm.detailList.length &&
                              !item.isApproved &&
                              !item.isUnfold
                            ? _c("img", {
                                staticClass: "approved",
                                attrs: {
                                  src: require("@/assets/images/const/noApproved.png"),
                                },
                              })
                            : !item.isUnfold &&
                              item.reviewType === 20 &&
                              $index !== _vm.detailList.length - 1
                            ? _c("img", {
                                staticClass: "approved",
                                attrs: {
                                  src: require("@/assets/images/const/examine_section.png"),
                                },
                              })
                            : !item.isUnfold &&
                              _vm.rowData.status === 25 &&
                              $index === _vm.detailList.length - 1
                            ? _c("img", {
                                staticClass: "approved",
                                attrs: {
                                  src: require("@/assets/images/const/examine_all.png"),
                                },
                              })
                            : _vm._e(),
                          $index < _vm.detailList.length
                            ? _c(
                                "div",
                                {
                                  staticClass: "unfold flex_c_c",
                                  on: {
                                    click: function ($event) {
                                      item.isUnfold = !item.isUnfold
                                    },
                                  },
                                },
                                [
                                  !item.isUnfold
                                    ? _c("img", {
                                        attrs: {
                                          src: require("@/assets/images/const/zhankai.png"),
                                        },
                                      })
                                    : _c("img", {
                                        attrs: {
                                          src: require("@/assets/images/const/shouqi.png"),
                                        },
                                      }),
                                  _vm._v(
                                    " " +
                                      _vm._s(item.isUnfold ? "收起" : "展开") +
                                      " "
                                  ),
                                ]
                              )
                            : _vm._e(),
                          _c("p", { staticClass: "title" }, [
                            _vm._v(" " + _vm._s(item.reviewTypeName) + " "),
                            item.isUnfold &&
                            _vm.rowData.status === 25 &&
                            $index === _vm.detailList.length - 1
                              ? _c("span", { staticClass: "examine_all" }, [
                                  _vm._v("全部审批"),
                                ])
                              : _vm._e(),
                          ]),
                          !_vm.rowData.isReviewPermission &&
                          $index === _vm.detailList.length - 1 &&
                          item.isUnfold
                            ? _c("div", { staticClass: "preview" }, [
                                _c("div", { staticClass: "preview-left" }, [
                                  _c("img", {
                                    attrs: {
                                      src: require("@/assets/images/pdf/pdf.png"),
                                    },
                                  }),
                                  _c("div", { staticClass: "preview-info" }, [
                                    _c("p", [_vm._v("付款申请单.pdf")]),
                                  ]),
                                ]),
                                _c(
                                  "div",
                                  {
                                    staticClass: "preview-right",
                                    on: { click: _vm.preview },
                                  },
                                  [
                                    _c("img", {
                                      attrs: {
                                        src: require("@/assets/images/system/look.png"),
                                      },
                                    }),
                                    _vm._v("预览 "),
                                  ]
                                ),
                              ])
                            : _vm._e(),
                          item.reviewType === 20 &&
                          item.isUnfold &&
                          _vm.detailList[$index].isApproved
                            ? _c("div", { staticClass: "preview" }, [
                                _c("div", { staticClass: "preview-left" }, [
                                  _c("img", {
                                    attrs: {
                                      src: require("@/assets/images/const/png.png"),
                                    },
                                  }),
                                  _c("div", { staticClass: "preview-info" }, [
                                    _c("p", [_vm._v("水单文件.jpg")]),
                                  ]),
                                ]),
                                _c(
                                  "div",
                                  {
                                    staticClass: "preview-right",
                                    on: {
                                      click: function ($event) {
                                        return _vm.previewImg(item.previewImg)
                                      },
                                    },
                                  },
                                  [
                                    _c("img", {
                                      attrs: {
                                        src: require("@/assets/images/system/look.png"),
                                      },
                                    }),
                                    _vm._v("预览 "),
                                  ]
                                ),
                              ])
                            : _vm._e(),
                          item.isUnfold
                            ? [
                                _c(
                                  "p",
                                  {
                                    staticClass: "des",
                                    staticStyle: { "margin-top": "10px" },
                                  },
                                  [
                                    _c("span", { staticClass: "des-label" }, [
                                      _vm._v("审批人："),
                                    ]),
                                    _c("span", { staticClass: "des-value" }, [
                                      _vm._v(
                                        _vm._s(
                                          _vm.detailList[$index].createdName
                                        )
                                      ),
                                    ]),
                                  ]
                                ),
                                _c("p", { staticClass: "des" }, [
                                  _c("span", { staticClass: "des-label" }, [
                                    _vm._v("审批时间："),
                                  ]),
                                  _c("span", { staticClass: "des-value" }, [
                                    _vm._v(
                                      _vm._s(
                                        _vm.detailList[$index].createdOn?.slice(
                                          0,
                                          _vm.detailList[$index].createdOn
                                            .length - 3
                                        )
                                      )
                                    ),
                                  ]),
                                ]),
                                +_vm.detailList[$index].reviewType === 20 &&
                                _vm.detailList[$index].isApproved
                                  ? _c("p", { staticClass: "des" }, [
                                      _c("span", { staticClass: "des-label" }, [
                                        _vm._v("支付金额："),
                                      ]),
                                      _c(
                                        "span",
                                        { staticClass: "des-value amount" },
                                        [
                                          _vm._v(
                                            _vm._s(
                                              _vm.detailList[$index].totalAmount
                                            ) + " USD"
                                          ),
                                        ]
                                      ),
                                    ])
                                  : _vm._e(),
                                +_vm.detailList[$index].reviewType === 20 &&
                                _vm.detailList[$index].isApproved
                                  ? _c("p", { staticClass: "des" }, [
                                      _c("span", { staticClass: "des-label" }, [
                                        _vm._v("支付时间："),
                                      ]),
                                      _c("span", { staticClass: "des-value" }, [
                                        _vm._v(
                                          _vm._s(
                                            _vm.detailList[
                                              $index
                                            ].paymentTime?.slice(
                                              0,
                                              _vm.detailList[$index].paymentTime
                                                .length - 9
                                            )
                                          )
                                        ),
                                      ]),
                                    ])
                                  : _vm._e(),
                                +_vm.detailList[$index].reviewType === 20 &&
                                _vm.detailList[$index].isApproved
                                  ? _c("p", { staticClass: "des" }, [
                                      _c("span", { staticClass: "des-label" }, [
                                        _vm._v("供应商："),
                                      ]),
                                      _c("span", { staticClass: "des-value" }, [
                                        _vm._v(
                                          _vm._s(_vm.rowData.supplierName)
                                        ),
                                      ]),
                                    ])
                                  : _vm._e(),
                                +_vm.detailList[$index].reviewType === 20 &&
                                _vm.detailList[$index].isApproved
                                  ? _c("p", { staticClass: "des" }, [
                                      _c(
                                        "span",
                                        {
                                          staticClass:
                                            "des-label bankAccount_label",
                                        },
                                        [_vm._v("收款账号：")]
                                      ),
                                      _c(
                                        "span",
                                        {
                                          staticClass: "des-value bankAccount",
                                        },
                                        [
                                          _c(
                                            "div",
                                            { staticClass: "account-t" },
                                            [
                                              _c(
                                                "span",
                                                {
                                                  class: [
                                                    "account_mark",
                                                    _vm.accountMark(
                                                      item.bankName
                                                    ),
                                                  ],
                                                },
                                                [_vm._v(_vm._s(item.bankName))]
                                              ),
                                              _c(
                                                "span",
                                                { staticClass: "account_no" },
                                                [
                                                  _vm._v(
                                                    _vm._s(
                                                      item.bankAccountNumber
                                                    )
                                                  ),
                                                ]
                                              ),
                                            ]
                                          ),
                                          _c(
                                            "div",
                                            { staticClass: "account-b" },
                                            [
                                              _vm._v(
                                                _vm._s(item.bankAccountName)
                                              ),
                                            ]
                                          ),
                                        ]
                                      ),
                                    ])
                                  : _vm._e(),
                                _c("p", { staticClass: "des" }, [
                                  _c("span", { staticClass: "des-label" }, [
                                    _vm._v("审批方式："),
                                  ]),
                                  _c(
                                    "span",
                                    {
                                      staticClass: "des-value",
                                      style: {
                                        color: _vm.detailList[$index].isApproved
                                          ? "#35BA83"
                                          : "#FE453D",
                                      },
                                    },
                                    [
                                      _vm._v(
                                        _vm._s(
                                          _vm.detailList[$index].isApproved
                                            ? "通过"
                                            : "驳回"
                                        )
                                      ),
                                    ]
                                  ),
                                ]),
                                _c("p", { staticClass: "des" }, [
                                  _c("span", { staticClass: "des-label" }, [
                                    _vm._v("审批意见："),
                                  ]),
                                  _c("span", { staticClass: "des-value" }, [
                                    _vm._v(
                                      _vm._s(
                                        _vm.detailList[$index].reviewComments
                                      )
                                    ),
                                  ]),
                                ]),
                                _c("p", { staticClass: "des" }, [
                                  _c("span", { staticClass: "des-label" }, [
                                    _vm._v("支付类型："),
                                  ]),
                                  _c("span", { staticClass: "des-value" }, [
                                    _vm._v(
                                      _vm._s(
                                        _vm.detailList[$index].payableTypeName
                                      )
                                    ),
                                  ]),
                                ]),
                                +_vm.detailList[$index].reviewType === 20 &&
                                +_vm.detailList[$index].defrayInvoice.length
                                  ? _c(
                                      "div",
                                      { staticClass: "des" },
                                      [
                                        _c(
                                          "div",
                                          { staticClass: "payment_bill" },
                                          [
                                            _c(
                                              "span",
                                              { staticClass: "des-label" },
                                              [_vm._v("支付账单：")]
                                            ),
                                            item.reviewType === 20 &&
                                            _vm.rowData.status === 20
                                              ? _c(
                                                  "span",
                                                  {
                                                    staticClass: "not_payment",
                                                    on: {
                                                      click: function ($event) {
                                                        _vm.dialogVisible1 = true
                                                      },
                                                    },
                                                  },
                                                  [
                                                    _c("i", {
                                                      staticClass:
                                                        "el-icon-zoom-in",
                                                    }),
                                                    _vm._v(" 未支付账单 "),
                                                  ]
                                                )
                                              : _vm._e(),
                                          ]
                                        ),
                                        _c("BaseTable", {
                                          attrs: {
                                            height: 240,
                                            tableData: {
                                              ..._vm.tableData2,
                                              columns:
                                                _vm.detailList[$index]
                                                  .defrayInvoice,
                                            },
                                            headerCellStyle:
                                              _vm.headerCellStyle,
                                            isPager: false,
                                          },
                                        }),
                                      ],
                                      1
                                    )
                                  : _vm._e(),
                              ]
                            : [
                                _c(
                                  "p",
                                  {
                                    staticClass: "des",
                                    staticStyle: { "margin-top": "5px" },
                                  },
                                  [
                                    _c(
                                      "span",
                                      {
                                        staticStyle: { "margin-right": "10px" },
                                      },
                                      [
                                        _vm._v(
                                          _vm._s(
                                            _vm.detailList[$index].createdName
                                          )
                                        ),
                                      ]
                                    ),
                                    _c("span", [
                                      _vm._v(
                                        "审批时间：" +
                                          _vm._s(
                                            _vm.detailList[$index].createdOn
                                          )
                                      ),
                                    ]),
                                  ]
                                ),
                              ],
                        ],
                        2
                      ),
                    ])
                  }),
                  _vm.rowData.isReviewPermission &&
                  (_vm.detailList[_vm.detailList.length - 1]?.reviewType !==
                    20 ||
                    !_vm.detailList[_vm.detailList.length - 1]?.isApproved)
                    ? _c("div", { staticClass: "panel" }, [
                        _c("div", { staticClass: "point-content" }, [
                          _vm.detailList.length !== 0
                            ? _c("div", { staticClass: "top-line acitve" })
                            : _vm._e(),
                          _c("div", { staticClass: "point acitve" }),
                          !_vm.detailList[_vm.detailList.length - 1]
                            ?.isApproved ||
                          (_vm.detailList[_vm.detailList.length - 1]
                            ?.reviewType !== 15 &&
                            _vm.detailList[_vm.detailList.length - 1]
                              ?.reviewType !== 20)
                            ? _c("div", { staticClass: "bottom-line" })
                            : _vm._e(),
                        ]),
                        _c(
                          "div",
                          { staticClass: "content" },
                          [
                            _c("p", { staticClass: "title" }, [
                              _vm._v(_vm._s(_vm.title)),
                            ]),
                            _c("div", { staticClass: "preview" }, [
                              _c("div", { staticClass: "preview-left" }, [
                                _c("img", {
                                  attrs: {
                                    src: require("@/assets/images/pdf/pdf.png"),
                                  },
                                }),
                                _c("div", { staticClass: "preview-info" }, [
                                  _c("p", [_vm._v("付款申请单.pdf")]),
                                ]),
                              ]),
                              _c(
                                "div",
                                {
                                  staticClass: "preview-right",
                                  on: { click: _vm.preview },
                                },
                                [
                                  _c("img", {
                                    attrs: {
                                      src: require("@/assets/images/system/look.png"),
                                    },
                                  }),
                                  _vm._v("预览 "),
                                ]
                              ),
                            ]),
                            _c(
                              "el-form",
                              {
                                ref: "ruleForm",
                                staticClass: "form",
                                attrs: {
                                  model: _vm.form,
                                  rules: _vm.rules,
                                  "label-position": "top",
                                },
                              },
                              [
                                _c(
                                  "el-form-item",
                                  {
                                    attrs: {
                                      prop: "isApproved",
                                      label: "审批方式",
                                    },
                                  },
                                  [
                                    _c(
                                      "el-radio-group",
                                      {
                                        model: {
                                          value: _vm.form.isApproved,
                                          callback: function ($$v) {
                                            _vm.$set(
                                              _vm.form,
                                              "isApproved",
                                              $$v
                                            )
                                          },
                                          expression: "form.isApproved",
                                        },
                                      },
                                      [
                                        _vm.detailList[
                                          _vm.detailList.length - 1
                                        ]?.reviewType !== 15
                                          ? _c(
                                              "el-radio",
                                              { attrs: { label: true } },
                                              [_vm._v("同意")]
                                            )
                                          : _vm._e(),
                                        _c(
                                          "el-radio",
                                          { attrs: { label: false } },
                                          [_vm._v("驳回")]
                                        ),
                                      ],
                                      1
                                    ),
                                  ],
                                  1
                                ),
                                _c(
                                  "el-form-item",
                                  {
                                    attrs: {
                                      prop: "reviewComments",
                                      label: "审批意见",
                                    },
                                  },
                                  [
                                    _c("el-input", {
                                      attrs: {
                                        type: "textarea",
                                        resize: "none",
                                        placeholder: "请输入",
                                        autosize: { minRows: 3, maxRows: 3 },
                                      },
                                      model: {
                                        value: _vm.form.reviewComments,
                                        callback: function ($$v) {
                                          _vm.$set(
                                            _vm.form,
                                            "reviewComments",
                                            $$v
                                          )
                                        },
                                        expression: "form.reviewComments",
                                      },
                                    }),
                                  ],
                                  1
                                ),
                              ],
                              1
                            ),
                            _c("div", { staticClass: "footer flex_c_c" }, [
                              _c(
                                "div",
                                {
                                  staticClass: "cancel flex_c_c",
                                  on: {
                                    click: function ($event) {
                                      return _vm.cancel("ruleForm")
                                    },
                                  },
                                },
                                [_vm._v(" 取消 ")]
                              ),
                              _c(
                                "div",
                                {
                                  directives: [
                                    {
                                      name: "loading",
                                      rawName: "v-loading",
                                      value: _vm.okLoading,
                                      expression: "okLoading",
                                    },
                                  ],
                                  staticClass: "confirm flex_c_c",
                                  on: {
                                    click: function ($event) {
                                      return _vm.ok("ruleForm")
                                    },
                                  },
                                },
                                [_vm._v("保存")]
                              ),
                            ]),
                          ],
                          1
                        ),
                      ])
                    : _vm._e(),
                  _vm._l(_vm.initList, function (item, $index) {
                    return _c(
                      "div",
                      { key: $index + 1, staticClass: "panel" },
                      [
                        _c("div", { staticClass: "point-content" }, [
                          _c("div", { staticClass: "top-line" }),
                          _c("div", { staticClass: "point" }),
                          _c("div", { staticClass: "bottom-line" }),
                        ]),
                        _c("div", { staticClass: "content" }, [
                          _c(
                            "p",
                            {
                              staticClass: "stay",
                              staticStyle: { color: "#999999" },
                            },
                            [_vm._v(_vm._s(item))]
                          ),
                        ]),
                      ]
                    )
                  }),
                ],
                2
              ),
            ]
          ),
        ]
      ),
      _c("PaymentDetailDialog", {
        attrs: {
          dialogVisible: _vm.dialogVisible1,
          rowData: _vm.rowData,
          type: 1,
        },
        on: {
          cancel: function ($event) {
            _vm.dialogVisible1 = false
          },
        },
      }),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }