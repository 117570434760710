<template>
  <el-dialog
    v-drag
    title="新增"
    :visible.sync="dialogVisible"
    width="800px"
    :show-close="true"
    :append-to-body="true"
    :close-on-click-modal="false"
    :close-on-press-escape="false"
    :before-close="cancel"
  >
    <div class="container">
      <TabStep v-if="form.type !== 2" class="tabstep" :active="active"></TabStep>
      <el-form
        v-show="active === 1"
        ref="ruleForm"
        :model="form"
        :rules="rules"
        label-position="top"
      >
        <el-row>
          <el-col :span="12">
            <el-form-item label="供应商" prop="supplierId">
              <el-select
                v-model="form.supplierId"
                style="width: 320px"
                :disabled="mergeDefrayObj.supplierId && addType !== 'add'"
                placeholder="请选择供应商"
                filterable
                @change="supplierChange"
              >
                <el-option
                  v-for="item in supplierOptions"
                  :key="item.value"
                  :label="item.label"
                  :value="item.value"
                >
                </el-option>
              </el-select>
            </el-form-item>
          </el-col>
          <el-col :span="12">
            <el-form-item label="申付单类型" prop="type">
              <el-select
                v-model="form.type"
                :disabled="mergeDefrayObj.supplierId && addType !== 'add'"
                style="width: 320px"
                placeholder="请选择申付单类型"
              >
                <el-option label="清提" :value="0"></el-option>
                <el-option label="派送" :value="1"></el-option>
                <el-option label="派送（预付）" :value="2"></el-option>
              </el-select>
            </el-form-item>
          </el-col>
        </el-row>
        <el-row>
          <el-col :span="12">
            <el-form-item label="期望支付日期" prop="expectedPaymentDate">
              <el-date-picker
                v-model="form.expectedPaymentDate"
                style="width: 320px"
                placeholder="请输入期望支付日期"
              ></el-date-picker>
            </el-form-item>
          </el-col>
          <el-col :span="12">
            <el-form-item label="币制选择" prop="currency">
              <el-input
                v-model="form.currency"
                style="width: 320px"
                disabled
                placeholder="请选择币制"
              ></el-input>
            </el-form-item>
          </el-col>
        </el-row>
        <el-row>
          <el-col :span="12">
            <el-form-item label="供应商银行账号" prop="supplierBankAccountId">
              <div class="custom_supplier" @click="customSelect">
                <div v-if="!form.supplierBankAccountId" class="placeholder">请选择供应商银行账号</div>
                <div v-if="form.supplierBankAccountId" class="account_box">
                  <div class="account-t">
                    <span :class="['account_mark', accountMark(selectAccount.bankName)]">{{ selectAccount.bankName }}</span>
                    <span class="account_no">{{ selectAccount.accountNumber }}</span>
                  </div>
                  <div class="account-b">{{ selectAccount.accountName }}</div>
                </div>
                <i :class="['el-icon-arrow-up', arrowDown && 'arrowDown']"></i>
              </div>
              <el-select
                ref="supplier"
                v-model="form.supplierBankAccountId"
                class="supplier"
                style="width: 320px;"
                placeholder="请选择供应商银行账号"
                @visible-change="(e) => {
                  !e && (arrowDown = false)
                }"
              >
                <el-option
                  v-for="item in bankAccountOptions"
                  :key="item.value"
                  :label="item.label"
                  :value="item.value"
                  class="accountlist-item"
                  @click.native="form.currency = item.currency"
                >
                  <div class="account_box">
                    <div class="account-t">
                      <span :class="['account_mark', accountMark(item.bankName)]">{{ item.bankName }}</span>
                      <span class="account_no">{{ item.accountNumber }}</span>
                    </div>
                    <div class="account-b">{{ item.accountName }}</div>
                  </div>
                </el-option>
              </el-select>
            </el-form-item>
          </el-col>
          <el-col v-if="form.type === 2" :span="12">
            <el-form-item label="金额" prop="totalAmount">
              <el-input
                v-model="form.totalAmount"
                style="width: 320px"
                placeholder="请输入金额"
                oninput="value=value.replace(/^\.+|[^\d.]/g,'')"
              ></el-input>
            </el-form-item>
          </el-col>
        </el-row>
        <el-row>
          <el-col :span="24">
            <el-form-item label="备注" prop="remark">
              <el-input
                v-model="form.remark"
                type="textarea"
                :autosize="{ minRows: 3, maxRows: 3 }"
                resize="none"
                placeholder="请输入备注内容"
              ></el-input>
            </el-form-item>
          </el-col>
        </el-row>
      </el-form>
      <div v-show="active === 2">
        <div class="header">
          <el-upload
            action="#"
            :show-file-list="false"
            :http-request="httpRequest"
          >
            <div class="import">
              <img src="@/assets/images/order/upload.png" />导入
            </div>
          </el-upload>
          <div class="download" @click="download">
            <img src="@/assets/images/order/download.png" />下载模板
          </div>
        </div>
        <div class="data">
          <BaseTable
            height="399"
            :tableData="tableData"
            :loading="loading"
            :isPager="false"
            :border="true"
          >
            <template slot="invoiceNumber" slot-scope="row">
              <span v-if="!row.scope.isSuccess">
                <el-tooltip
                  class="item"
                  effect="dark"
                  :content="row.scope.errorMessage"
                  placement="bottom"
                >
                  <span>
                    <i class="icon el-icon-warning-outline"></i>
                  </span>
                </el-tooltip>
                {{ row.scope.invoiceNumber }}
              </span>
              <span v-else>{{ row.scope.invoiceNumber }}</span>
            </template>
          </BaseTable>
        </div>
      </div>
    </div>
    <span slot="footer" class="footers">
      <div class="cancel flex_c_c" @click="cancel">取 消</div>
      <div v-if="active !== 1" class="ok flex_c_c" @click="last">上一步</div>
      <div class="ok flex_c_c" @click="ok('ruleForm')">
        {{ active === 1 && form.type !== 2 ? "下一步" : "确定" }}
      </div>
    </span>
  </el-dialog>
</template>
<script>
import * as XLSX from 'xlsx'
const BaseTable = () => import('@/components/table/BaseTable')
import { getSupplierDropDownList, getSupplierBankAccount } from '@/api/system'
import { addDefray, checkAddDefray } from '@/api/fee'
import TabStep from './TabStep.vue'
import config from '@/utils/config'
export default {
  components: { TabStep, BaseTable },
  props: {
    dialogVisible: {
      type: Boolean,
      default: false
    },
    mergeDefrayObj: {
      // {
      //   supplierId: '',
      //   type: '',
      //   tableList: [{
          //   invoiceNumber,
          //   blNo,
          //   supplierName,
          //   amount,
          //   currency
      // }]
      // }
      type: Object,
      default: function() {
        return {}
      }
    },
    addType: {
      type: String,
      default: 'add'
    }
  },
  data() {
    return {
      active: 1,
      loading: false,
      tableData: {
        fileds: [
          {
            filed: 'invoiceNumber',
            label: '发票号',
            width: '170px',
            isCustom: true,
            resizable: true
          },
          {
            filed: 'blNo',
            label: '单号',
            width: '170px',
            resizable: true
          },
          {
            filed: 'supplierName',
            label: '供应商',
            width: '200px',
            resizable: true
          },
          {
            filed: 'amount',
            label: '总金额',
            width: '120px',
            resizable: true
          },
          {
            filed: 'currency',
            label: '币制',
            width: '100px',
            resizable: true
          }
        ],
        columns: [],
        tableRowClassName({ row }) {
          if (!row.isSuccess) {
            return 'error-row'
          }
        }
      },
      form: {
        supplierId: '',
        supplierBankAccountId: '',
        type: '',
        expectedPaymentDate: '',
        currency: '',
        remark: '',
        totalAmount: ''
      },
      rules: {
        supplierId: [
          { required: true, message: '请选择供应商', trigger: 'change' }
        ],
        totalAmount: [
          { required: true, message: '请输入金额', trigger: 'blur' }
        ],
        supplierBankAccountId: [
          {
            required: true,
            message: '请选择供应商银行账号',
            trigger: 'change'
          }
        ],
        type: [
          { required: true, message: '请选择申付单类型', trigger: 'change' }
        ],
        expectedPaymentDate: [
          { required: true, message: '请输入期望支付日期', trigger: 'blur' }
        ],
        currency: [{ required: true, message: '请选择币制', trigger: 'blur' }]
      },
      supplierOptions: [],
      bankAccountOptions: [],
      arrowDown: false
    }
  },
  computed: {
    selectAccount() {
      return this.bankAccountOptions.filter(a => a.id === this.form.supplierBankAccountId)[0]
    }
  },
  watch: {
    dialogVisible: function(val) {
      if (val) {
        this.getCommData()
        if (this.mergeDefrayObj.supplierId && this.addType !== 'add') {
          this.form.supplierId = this.mergeDefrayObj.supplierId
          this.form.type = this.mergeDefrayObj.type
          this.tableData.columns = this.mergeDefrayObj.columns
        } else {
          this.form.supplierId = ''
          this.form.type = ''
        }
      }
    }
  },
  methods: {
    customSelect() {
      this.arrowDown = !this.arrowDown
      this.$nextTick(() => {
        this.arrowDown && this.$refs.supplier.toggleMenu()
        !this.arrowDown && this.$refs.supplier.blur()
      })
    },
    getCommData() {
      getSupplierDropDownList({}).then((res) => {
        if (res.success) {
          res.data &&
            (this.supplierOptions = res.data.map((a) => {
              return {
                label: `${a.code}-${a.name}`,
                value: a.id
              }
            }))
        } else {
          this.$message.error(res.errorMessage)
        }
      })
    },
    getCommBankAccount(v) {
      getSupplierBankAccount({ supplierId: v }).then((res) => {
        res &&
          (this.bankAccountOptions = res.map((a) => {
            return {
              ...a,
              label: a.accountNumber,
              value: a.id,
              currency: a.currency
            }
          }))
      })
    },
    supplierChange(v) {
      this.form.supplierBankAccountId = ''
      this.form.currency = ''
      this.getCommBankAccount(v)
    },
    cancel() {
      this.active = 1
      this.tableData.columns = []
      this.bankAccountOptions = []
      this.$refs['ruleForm'].resetFields()
      this.$emit('cancel')
    },
    ok(formName) {
      this.$refs[formName].validate(async(valid) => {
        if (valid) {
          if (this.active === 1 && this.form.type !== 2) {
             this.active = 2
          } else {
            if (
              this.form.type === 0 &&
              this.tableData.columns.some((a) => a.blNo === '')
            ) {
              this.$message.error('申付类型为清提，单号不能为空')
              this.loading = false
              return
            }
            if (this.form.type === 2 && !this.form.totalAmount) {
              this.$message.error('金额不能为0')
              return
            }
            const params = {
              ...this.form,
              invoiceDetails: this.form.type !== 2 ? this.tableData.columns : []
            }
            try {
              const result = await addDefray(params)
              if (result.success) {
                this.$emit('ok')
                this.$message.success(result.errorMessage)
              } else {
                this.$message.error(result.errorMessage)
              }
            } catch (error) {
              console.error(error)
            }
          }
        } else {
          return false
        }
      })
    },
    last() {
      this.active > 1 && (this.active = this.active - 1)
    },
    download() {
      const elink = document.createElement('a')
      elink.style.display = 'none'
      elink.href = `/backEnd/api/${config.supplyCode}/file/downLoadTemplate?guid=c3c81d476e8c49a6bf36851140192739`
      document.body.appendChild(elink)
      elink.click()
      URL.revokeObjectURL(elink.href)
      document.body.removeChild(elink)
    },
    async httpRequest({ file }) {
      const suffix = file.name.slice(
        file.name.lastIndexOf('.') + 1,
        file.name.length
      )
      if (suffix !== 'xlsx') {
        this.$message.warning('请上传xlsx文件')
        return
      }
      const reader = new FileReader()
      const that = this
      that.loading = true
      reader.onload = async function(e) {
        const data = e.target.result
        const wb = XLSX.read(data, { type: 'binary' })
        const jdata = XLSX.utils.sheet_to_json(wb.Sheets[wb.SheetNames[0]])
        let flag = true
        if (Object.keys(wb.Sheets[wb.SheetNames[0]]).indexOf('D1') > -1) {
          that.$message.error('导入模板有误，请下载模板参考')
          that.loading = false
          flag = false
        }
        if (Object.keys(wb.Sheets[wb.SheetNames[0]]).indexOf('C1') === -1) {
          that.$message.error('导入模板有误，请下载模板参考')
          that.loading = false
          flag = false
        }
        if (!flag) return
        const newData = jdata.map((a) => {
          const newKey = Object.keys(a).sort()
          const newObj = {}
          newKey.forEach((b, i) => {
            newObj[newKey[i]] = a[newKey[i]]
          })
          return {
            invoiceNumber: newObj['发票号']
              ? newObj['发票号']
              : '',
            blNo: newObj['单号'] ? newObj['单号'] : '',
            amount: newObj['总金额'] ? newObj['总金额'] : ''
          }
        })
        try {
          const params = {
            ...that.form,
            invoiceDetails: newData
          }
          const result = await checkAddDefray(params)
          if (result.data) {
            that.tableData.columns = result.data?.map((a) => {
              return {
                ...a,
                supplierName: that.supplierOptions.find(
                  (a) => a.value === that.form.supplierId
                )?.label,
                currency: that.form.currency
              }
            })
            that.loading = false
          } else {
            that.$message.error(result.errorMessage)
            that.loading = false
          }
        } catch (error) {
          console.error(error)
        }
      }
      reader.readAsBinaryString(file)
    },
    accountMark(v) {
      const mark1 = ['中国银行', '招商银行', '工商银行', '中信银行']
      const mark2 = ['中国建设银行', '交通银行', '美国银行']
      const mark3 = ['农业银行']
      const mark4 = ['汇丰银行']
      const mark5 = ['花旗银行']
      if (mark1.includes(v)) {
        return 'account_mark1'
      }
      if (mark2.includes(v)) {
        return 'account_mark2'
      }
      if (mark3.includes(v)) {
        return 'account_mark3'
      }
      if (mark4.includes(v)) {
        return 'account_mark4'
      }
      if (mark5.includes(v)) {
        return 'account_mark5'
      }
      if (!(mark1.includes(v) && mark2.includes(v) && mark3.includes(v) && mark4.includes(v) && mark5.includes(v))) {
        return 'account_mark1'
      }
    }
  }
}
</script>

<style lang="less" scoped>
.container {
  position: relative;
  height: 467px;
  .tabstep {
    position: absolute;
    top: -40px;
    left: 50%;
    transform: translateX(-50%);
  }
  .el-form {
    width: calc(100% - 80px);
    margin: 0 auto;
    padding-top: 40px;
    box-sizing: border-box;
    .el-col:nth-child(2n) {
      display: flex;
      justify-content: flex-end;
    }
    .custom_supplier {
      position: absolute;
      display: flex;
      align-items: center;
      width: 320px;
      height: 100%;
      border-radius: 4px;
      border: 1px solid #DCDFE6;
      box-sizing: border-box;
      background-color: #FFF;
      z-index: 1;
      cursor: pointer;
      padding: 0 15px;
      & .el-icon-arrow-up {
        position: absolute;
        right: 10px;
        top: 50%;
        transform: translateY(-50%) rotateZ(180deg);
        width: 14px;
        height: 14px;
        color: #C0C4CC;
        font-size: 14px;
        transition: transform .3s;
      }
      & .arrowDown {
        transform: translateY(-50%) rotateZ(0deg);
      }
      .placeholder {
        font-size: 14px;
        font-family: Microsoft YaHei-Regular, Microsoft YaHei;
        font-weight: 400;
        color: #c0c4cc;
      }
    }
    .supplier {
      height: 60px;
      /deep/ .el-input,
      /deep/ input {
        height: 100%;
      }
    }
  }
  .header {
    display: flex;
    justify-content: flex-end;
    height: 60px;
    padding-top: 20px;
    box-sizing: border-box;
    & div {
      display: flex;
      justify-content: center;
      align-items: center;
      height: 30px;
      border-radius: 2px 2px 2px 2px;
      font-size: 14px;
      font-family: Microsoft YaHei-Regular, Microsoft YaHei;
      font-weight: 400;
      box-sizing: border-box;
      cursor: pointer;
    }
    .import {
      width: 80px;
      background: #35ba83;
      color: #ffffff;
      margin-right: 5px;
    }
    .download {
      width: 120px;
      border: 1px solid #35ba83;
      color: #35ba83;
    }
  }
  .data {
    border-radius: 4px;
    overflow: hidden;
    .icon {
      margin-right: 5px;
    }
  }
}
/deep/ .el-table .error-row {
  background: rgba(254, 69, 61, 0.1);
  color: #fe453d;
}
.footers {
  width: 100%;
  display: flex;
  justify-content: center;
  .cancel {
    width: 120px;
    height: 40px;
    background: #eeeeee;
    border-radius: 4px 4px 4px 4px;
    font-size: 14px;
    font-family: Microsoft YaHei-Regular, Microsoft YaHei;
    font-weight: 400;
    color: #999999;
    cursor: pointer;
  }
  .ok {
    width: 120px;
    height: 40px;
    background: #2395f3;
    border-radius: 4px 4px 4px 4px;
    font-size: 14px;
    cursor: pointer;
    font-family: Microsoft YaHei-Regular, Microsoft YaHei;
    font-weight: 400;
    color: #ffffff;
    margin-left: 20px;
  }
}
.account_box div {
  line-height: normal;
}
.account-b {
  font-size: 12px;
  font-family: Microsoft YaHei-Regular, Microsoft YaHei;
  font-weight: 400;
  color: #999999;
  margin-top: 5px;
}
.account_mark {
  display: inline-block;
  width: auto;
  height: 20px;
  border-radius: 2px 2px 2px 2px;
  padding: 2px 6px;
  box-sizing: border-box;
  margin-right: 5px;
  font-size: 12px;
  font-family: Microsoft YaHei-Regular, Microsoft YaHei;
  font-weight: 400;
}
.account_mark1 {
  background: rgba(254, 69, 61, .1);
  color: #FE453D;
}
.account_mark2 {
  background: rgba(35, 149, 243, .1);
  color: #2395F3;
}
.account_mark3 {
  background: rgba(53, 186, 131, .1);
  color: #35BA83;
}
.account_mark4 {
  background: rgba(255, 116, 68, .1);
  color: #FF7444;
}
.account_mark5 {
  background: rgba(157, 103, 254, .1);
  color: #9D67FE;
}
.accountlist-item {
  display: flex;
  align-items: center;
  height: 60px;
  border-bottom: 1px solid #EEEEEE;
  box-sizing: border-box;
}
</style>
