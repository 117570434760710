var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "div",
    { staticClass: "view-contanier" },
    [
      _c("div", { staticClass: "header" }, [
        _c(
          "div",
          { staticClass: "tab" },
          _vm._l(_vm.tabList, function (item, $index) {
            return _c(
              "span",
              {
                directives: [
                  {
                    name: "permissson",
                    rawName: "v-permissson",
                    value: `${item.permissson}`,
                    expression: "`${item.permissson}`",
                  },
                ],
                key: item.permissson,
                class: ["tabItem", _vm.active === $index ? "active" : ""],
                on: {
                  click: function ($event) {
                    return _vm.changeType($index, item.permissson)
                  },
                },
              },
              [_vm._v(" " + _vm._s(item.name) + " ")]
            )
          }),
          0
        ),
        _c(
          "div",
          { staticClass: "opt" },
          [
            _vm.activeCode === "QB" &&
            _vm.isActive1 &&
            _vm.filterList1.invoiceDate
              ? _c("div", { staticClass: "checkDate flex_c_c" }, [
                  _vm._v(
                    " 查询时间：" +
                      _vm._s(
                        _vm.filterList1.invoiceDate[0] +
                          "~" +
                          _vm.filterList1.invoiceDate[1]
                      ) +
                      " "
                  ),
                ])
              : _vm._e(),
            _vm.activeCode === "SQJL"
              ? _c(
                  "div",
                  {
                    directives: [
                      {
                        name: "permissson",
                        rawName: "v-permissson",
                        value: "payment",
                        expression: "'payment'",
                      },
                    ],
                    staticClass: "payment flex_c_c",
                    on: { click: _vm.payment },
                  },
                  [
                    _c("img", {
                      attrs: {
                        src: require("@/assets/images/const/payment.png"),
                      },
                    }),
                    _vm._v("支付 "),
                  ]
                )
              : _vm._e(),
            _vm.activeCode === "QB"
              ? _c(
                  "div",
                  {
                    directives: [
                      {
                        name: "permissson",
                        rawName: "v-permissson",
                        value: "merge",
                        expression: "'merge'",
                      },
                    ],
                    staticClass: "merge flex_c_c",
                    on: {
                      click: function ($event) {
                        return _vm.merge("merge")
                      },
                    },
                  },
                  [
                    _c("img", {
                      attrs: {
                        src: require("@/assets/images/const/merge.png"),
                      },
                    }),
                    _vm._v("合并申付 "),
                  ]
                )
              : _vm._e(),
            _vm.activeCode === "QB"
              ? _c(
                  "div",
                  {
                    directives: [
                      {
                        name: "permissson",
                        rawName: "v-permissson",
                        value: "derive",
                        expression: "'derive'",
                      },
                    ],
                    staticClass: "derive flex_c_c",
                    on: { click: _vm.derive },
                  },
                  [
                    _c("img", {
                      attrs: {
                        src: require("@/assets/images/const/derive.png"),
                      },
                    }),
                    _vm._v("导出 "),
                  ]
                )
              : _vm._e(),
            _vm.activeCode === "QB"
              ? _c(
                  "div",
                  {
                    directives: [
                      {
                        name: "permissson",
                        rawName: "v-permissson",
                        value: "add",
                        expression: "'add'",
                      },
                    ],
                    staticClass: "add flex_c_c",
                    on: {
                      click: function ($event) {
                        return _vm.add("add")
                      },
                    },
                  },
                  [
                    _c("svg-icon", {
                      attrs: { className: "icon", iconClass: "add" },
                    }),
                    _vm._v("新增 "),
                  ],
                  1
                )
              : _vm._e(),
            _c(
              "BaseFilter",
              {
                attrs: { isActive: _vm.filterActive, width: _vm.filterWidth },
                on: { sendFilter: _vm.sendFilter, reset: _vm.reset },
              },
              [
                _c(
                  "div",
                  { staticClass: "filter" },
                  [
                    !_vm.active
                      ? _c(
                          "el-form",
                          {
                            ref: "form",
                            attrs: {
                              model: _vm.filterList1,
                              "label-width": "104px",
                            },
                          },
                          [
                            !_vm.active
                              ? _c(
                                  "el-form-item",
                                  { attrs: { label: "账单日期" } },
                                  [
                                    _c("el-date-picker", {
                                      staticStyle: { width: "320px" },
                                      attrs: {
                                        type: "daterange",
                                        "range-separator": "至",
                                        align: "center",
                                        "start-placeholder": "开始日期",
                                        "end-placeholder": "结束日期",
                                        "value-format": "yyyy-MM-dd",
                                        "unlink-panels": "",
                                        "picker-options": _vm.pickerOptions,
                                      },
                                      model: {
                                        value: _vm.filterList1.invoiceDate,
                                        callback: function ($$v) {
                                          _vm.$set(
                                            _vm.filterList1,
                                            "invoiceDate",
                                            $$v
                                          )
                                        },
                                        expression: "filterList1.invoiceDate",
                                      },
                                    }),
                                  ],
                                  1
                                )
                              : _vm._e(),
                            _c(
                              "el-form-item",
                              { attrs: { label: "申付状态" } },
                              [
                                _c(
                                  "el-select",
                                  {
                                    staticStyle: { width: "320px" },
                                    attrs: { placeholder: "请选择" },
                                    model: {
                                      value: _vm.filterList1.defrayStatus,
                                      callback: function ($$v) {
                                        _vm.$set(
                                          _vm.filterList1,
                                          "defrayStatus",
                                          $$v
                                        )
                                      },
                                      expression: "filterList1.defrayStatus",
                                    },
                                  },
                                  _vm._l(
                                    _vm.claimPayDefrayStatusOption,
                                    function (item) {
                                      return _c("el-option", {
                                        key: item.value,
                                        attrs: {
                                          label: item.label,
                                          value: item.value,
                                        },
                                      })
                                    }
                                  ),
                                  1
                                ),
                              ],
                              1
                            ),
                            _c(
                              "el-form-item",
                              { attrs: { label: "申付类型" } },
                              [
                                _c(
                                  "el-select",
                                  {
                                    staticStyle: { width: "320px" },
                                    attrs: { placeholder: "请选择" },
                                    model: {
                                      value: _vm.filterList1.type,
                                      callback: function ($$v) {
                                        _vm.$set(_vm.filterList1, "type", $$v)
                                      },
                                      expression: "filterList1.type",
                                    },
                                  },
                                  _vm._l(
                                    _vm.claimPayDefrayTypeOption,
                                    function (item) {
                                      return _c("el-option", {
                                        key: item.value,
                                        attrs: {
                                          label: item.label,
                                          value: item.value,
                                        },
                                      })
                                    }
                                  ),
                                  1
                                ),
                              ],
                              1
                            ),
                            _c(
                              "el-form-item",
                              { attrs: { label: "供应商" } },
                              [
                                _c(
                                  "el-select",
                                  {
                                    staticStyle: { width: "320px" },
                                    attrs: { placeholder: "请选择" },
                                    model: {
                                      value: _vm.filterList1.supplierId,
                                      callback: function ($$v) {
                                        _vm.$set(
                                          _vm.filterList1,
                                          "supplierId",
                                          $$v
                                        )
                                      },
                                      expression: "filterList1.supplierId",
                                    },
                                  },
                                  _vm._l(_vm.supplierOptions, function (item) {
                                    return _c("el-option", {
                                      key: item.value,
                                      attrs: {
                                        label: item.label,
                                        value: item.value,
                                      },
                                    })
                                  }),
                                  1
                                ),
                              ],
                              1
                            ),
                            _c(
                              "el-form-item",
                              { attrs: { label: "发票号/提单号" } },
                              [
                                _c("base-muti-input", {
                                  ref: "baseMutiInput1",
                                  staticStyle: { width: "320px" },
                                  model: {
                                    value: _vm.filterList1.numbers,
                                    callback: function ($$v) {
                                      _vm.$set(_vm.filterList1, "numbers", $$v)
                                    },
                                    expression: "filterList1.numbers",
                                  },
                                }),
                              ],
                              1
                            ),
                          ],
                          1
                        )
                      : _vm.active === 1
                      ? _c(
                          "el-form",
                          {
                            ref: "form",
                            attrs: {
                              model: _vm.filterList2,
                              "label-width": "104px",
                            },
                          },
                          [
                            _c(
                              "el-form-item",
                              { attrs: { label: "申付状态" } },
                              [
                                _c(
                                  "el-select",
                                  {
                                    staticStyle: { width: "320px" },
                                    attrs: { placeholder: "请选择" },
                                    model: {
                                      value: _vm.filterList2.status,
                                      callback: function ($$v) {
                                        _vm.$set(_vm.filterList2, "status", $$v)
                                      },
                                      expression: "filterList2.status",
                                    },
                                  },
                                  _vm._l(
                                    _vm.claimPayStatusOption,
                                    function (item) {
                                      return _c("el-option", {
                                        key: item.value,
                                        attrs: {
                                          label: item.label,
                                          value: item.value,
                                        },
                                      })
                                    }
                                  ),
                                  1
                                ),
                              ],
                              1
                            ),
                            _c(
                              "el-form-item",
                              { attrs: { label: "申付类型" } },
                              [
                                _c(
                                  "el-select",
                                  {
                                    staticStyle: { width: "320px" },
                                    attrs: { placeholder: "请选择" },
                                    model: {
                                      value: _vm.filterList2.type,
                                      callback: function ($$v) {
                                        _vm.$set(_vm.filterList2, "type", $$v)
                                      },
                                      expression: "filterList2.type",
                                    },
                                  },
                                  _vm._l(
                                    _vm.claimPayDefrayTypeOption,
                                    function (item) {
                                      return _c("el-option", {
                                        key: item.value,
                                        attrs: {
                                          label: item.label,
                                          value: item.value,
                                        },
                                      })
                                    }
                                  ),
                                  1
                                ),
                              ],
                              1
                            ),
                            _c(
                              "el-form-item",
                              { attrs: { label: "供应商" } },
                              [
                                _c(
                                  "el-select",
                                  {
                                    staticStyle: { width: "320px" },
                                    attrs: { placeholder: "请选择" },
                                    model: {
                                      value: _vm.filterList2.supplierId,
                                      callback: function ($$v) {
                                        _vm.$set(
                                          _vm.filterList2,
                                          "supplierId",
                                          $$v
                                        )
                                      },
                                      expression: "filterList2.supplierId",
                                    },
                                  },
                                  _vm._l(_vm.supplierOptions, function (item) {
                                    return _c("el-option", {
                                      key: item.value,
                                      attrs: {
                                        label: item.label,
                                        value: item.value,
                                      },
                                    })
                                  }),
                                  1
                                ),
                              ],
                              1
                            ),
                            _c(
                              "el-form-item",
                              { attrs: { label: "发票号/提单号" } },
                              [
                                _c("base-muti-input", {
                                  ref: "baseMutiInput2",
                                  staticStyle: { width: "320px" },
                                  model: {
                                    value: _vm.filterList2.numbers,
                                    callback: function ($$v) {
                                      _vm.$set(_vm.filterList2, "numbers", $$v)
                                    },
                                    expression: "filterList2.numbers",
                                  },
                                }),
                              ],
                              1
                            ),
                          ],
                          1
                        )
                      : _c(
                          "el-form",
                          {
                            ref: "form",
                            attrs: {
                              model: _vm.filterList3,
                              "label-width": "74px",
                            },
                          },
                          [
                            _c(
                              "el-form-item",
                              { attrs: { label: "支付时间" } },
                              [
                                _c("el-date-picker", {
                                  staticStyle: { width: "320px" },
                                  attrs: {
                                    type: "daterange",
                                    "range-separator": "至",
                                    align: "center",
                                    "start-placeholder": "开始日期",
                                    "end-placeholder": "结束日期",
                                    "value-format": "yyyy-MM-dd",
                                    "picker-options": _vm.pickerOptions,
                                  },
                                  model: {
                                    value: _vm.filterList3.date,
                                    callback: function ($$v) {
                                      _vm.$set(_vm.filterList3, "date", $$v)
                                    },
                                    expression: "filterList3.date",
                                  },
                                }),
                              ],
                              1
                            ),
                            _c(
                              "el-form-item",
                              { attrs: { label: "供应商" } },
                              [
                                _c(
                                  "el-select",
                                  {
                                    staticStyle: { width: "320px" },
                                    attrs: { placeholder: "请选择" },
                                    model: {
                                      value: _vm.filterList3.supplierId,
                                      callback: function ($$v) {
                                        _vm.$set(
                                          _vm.filterList3,
                                          "supplierId",
                                          $$v
                                        )
                                      },
                                      expression: "filterList3.supplierId",
                                    },
                                  },
                                  _vm._l(_vm.supplierOptions, function (item) {
                                    return _c("el-option", {
                                      key: item.value,
                                      attrs: {
                                        label: item.label,
                                        value: item.value,
                                      },
                                    })
                                  }),
                                  1
                                ),
                              ],
                              1
                            ),
                          ],
                          1
                        ),
                  ],
                  1
                ),
              ]
            ),
          ],
          1
        ),
      ]),
      _c(
        "div",
        { staticClass: "data" },
        [
          _vm.height !== 0
            ? _c("BaseTable", {
                key: _vm.active,
                attrs: {
                  height: _vm.height,
                  tableData: {
                    ..._vm.tableData,
                    fileds: _vm.fileds,
                  },
                  pageIndex: _vm.pager.pageIndex,
                  pageSize: _vm.pager.pageSize,
                  loading: _vm.loading,
                },
                on: {
                  handlePageChange: _vm.handlePageChange,
                  handleSelectionChange: _vm.handleSelectionChange,
                  sortChange: _vm.sortChange,
                },
                scopedSlots: _vm._u(
                  [
                    {
                      key: "status",
                      fn: function (row) {
                        return [
                          _c(
                            "span",
                            { class: [`status${row.scope.status}`, "status"] },
                            [_vm._v(" " + _vm._s(row.scope.statusName) + " ")]
                          ),
                        ]
                      },
                    },
                    {
                      key: "defrayStatus",
                      fn: function (row) {
                        return [
                          _c(
                            "span",
                            {
                              class: [
                                `status${row.scope.defrayStatus}`,
                                "status",
                              ],
                            },
                            [
                              _vm._v(
                                " " +
                                  _vm._s(
                                    _vm._f("formatterDefrayStatus")(
                                      row.scope.defrayStatus
                                    )
                                  ) +
                                  " "
                              ),
                            ]
                          ),
                        ]
                      },
                    },
                    {
                      key: "bankAccountNumber",
                      fn: function (row) {
                        return [
                          _c("div", { staticClass: "account_box" }, [
                            _c("div", { staticClass: "account-t" }, [
                              _c(
                                "span",
                                {
                                  class: [
                                    "account_mark",
                                    _vm.accountMark(row.scope.bankName),
                                  ],
                                },
                                [_vm._v(_vm._s(row.scope.bankName))]
                              ),
                              _c("span", { staticClass: "account_no" }, [
                                _vm._v(_vm._s(row.scope.bankAccountNumber)),
                              ]),
                            ]),
                            _c("div", { staticClass: "account-b" }, [
                              _vm._v(_vm._s(row.scope.bankAccountName)),
                            ]),
                          ]),
                        ]
                      },
                    },
                    {
                      key: "opt",
                      fn: function (row) {
                        return [
                          _c(
                            "div",
                            { staticClass: "table-opt" },
                            [
                              _vm.active === 0
                                ? [
                                    _c(
                                      "span",
                                      {
                                        directives: [
                                          {
                                            name: "permissson",
                                            rawName: "v-permissson",
                                            value: "detail",
                                            expression: "'detail'",
                                          },
                                        ],
                                        on: {
                                          click: function ($event) {
                                            return _vm.copyWithdetail(row.scope)
                                          },
                                        },
                                      },
                                      [_vm._v("详情")]
                                    ),
                                  ]
                                : _vm._e(),
                              _vm.active === 1
                                ? [
                                    _c(
                                      "span",
                                      {
                                        directives: [
                                          {
                                            name: "permissson",
                                            rawName: "v-permissson",
                                            value: "audit",
                                            expression: "'audit'",
                                          },
                                        ],
                                        on: {
                                          click: function ($event) {
                                            return _vm.audit(row.scope)
                                          },
                                        },
                                      },
                                      [_vm._v("审批")]
                                    ),
                                    _c(
                                      "span",
                                      {
                                        directives: [
                                          {
                                            name: "permissson",
                                            rawName: "v-permissson",
                                            value: "detail",
                                            expression: "'detail'",
                                          },
                                        ],
                                        on: {
                                          click: function ($event) {
                                            return _vm.detail(row.scope)
                                          },
                                        },
                                      },
                                      [_vm._v("查看明细")]
                                    ),
                                    _c(
                                      "span",
                                      {
                                        directives: [
                                          {
                                            name: "permissson",
                                            rawName: "v-permissson",
                                            value: "delete",
                                            expression: "'delete'",
                                          },
                                        ],
                                        on: {
                                          click: function ($event) {
                                            return _vm.deleteRow(row.scope)
                                          },
                                        },
                                      },
                                      [_vm._v("删除")]
                                    ),
                                  ]
                                : _vm._e(),
                              _vm.active === 2
                                ? [
                                    _c(
                                      "span",
                                      {
                                        directives: [
                                          {
                                            name: "permissson",
                                            rawName: "v-permissson",
                                            value: "billingDetail",
                                            expression: "'billingDetail'",
                                          },
                                        ],
                                        on: {
                                          click: function ($event) {
                                            return _vm.billingDetail(row.scope)
                                          },
                                        },
                                      },
                                      [_vm._v("账单明细")]
                                    ),
                                    _c(
                                      "span",
                                      {
                                        directives: [
                                          {
                                            name: "permissson",
                                            rawName: "v-permissson",
                                            value: "bankSlip",
                                            expression: "'bankSlip'",
                                          },
                                        ],
                                        on: {
                                          click: function ($event) {
                                            return _vm.bankSlip(row.scope)
                                          },
                                        },
                                      },
                                      [_vm._v("查看水单")]
                                    ),
                                  ]
                                : _vm._e(),
                            ],
                            2
                          ),
                        ]
                      },
                    },
                    {
                      key: "typeName",
                      fn: function (row) {
                        return [
                          _c(
                            "div",
                            { class: ["type", `type${row.scope.type}`] },
                            [_vm._v(_vm._s(row.scope.typeName))]
                          ),
                        ]
                      },
                    },
                    {
                      key: "custom-total",
                      fn: function () {
                        return [
                          _c("div", { staticClass: "custom-total" }, [
                            _c("div", { staticStyle: { display: "flex" } }, [
                              _c("div", { staticClass: "select" }, [
                                _vm._v(
                                  "已选 " +
                                    _vm._s(_vm.selectionItem.length) +
                                    " 项"
                                ),
                              ]),
                              _vm.selectionItem.length
                                ? _c("div", {
                                    staticClass: "total",
                                    domProps: {
                                      innerHTML: _vm._s(_vm.totalHtml),
                                    },
                                  })
                                : _vm._e(),
                            ]),
                          ]),
                        ]
                      },
                      proxy: true,
                    },
                  ],
                  null,
                  false,
                  4076108402
                ),
              })
            : _vm._e(),
        ],
        1
      ),
      _c("AddClaimPaymentDialog", {
        attrs: {
          dialogVisible: _vm.dialogVisible1,
          mergeDefrayObj: _vm.mergeDefrayObj,
          addType: _vm.addType,
        },
        on: {
          cancel: function ($event) {
            _vm.dialogVisible1 = false
          },
          ok: _vm.ok,
        },
      }),
      _c("FinancialApprovalDialog", {
        attrs: {
          dialogVisible: _vm.dialogVisible3,
          rowData: _vm.rowData,
          selectionItem: _vm.selectionItem,
          invoiceArr: _vm.invoiceArr,
          bankAccountInfo: _vm.bankAccountInfo,
        },
        on: {
          cancel: function ($event) {
            _vm.dialogVisible3 = false
          },
          ok: _vm.ok,
        },
      }),
      _c("PaymentDetailDialog", {
        attrs: {
          dialogVisible: _vm.dialogVisible4,
          rowData: _vm.rowData,
          type: 0,
        },
        on: {
          cancel: function ($event) {
            _vm.dialogVisible4 = false
          },
        },
      }),
      _c("ViewDetailDrawer", {
        attrs: { drawer: _vm.drawer1, defrayId: _vm.rowData.id },
        on: {
          close: function ($event) {
            _vm.drawer1 = false
          },
        },
      }),
      _c("ExamineApproveDrawer", {
        attrs: { drawer: _vm.drawer2, rowData: _vm.rowData },
        on: {
          close: function ($event) {
            _vm.drawer2 = false
          },
          ok: _vm.ok2,
        },
      }),
      _c("CopeWithDetailDialog", {
        attrs: { dialogVisible: _vm.dialogVisible5, rowData: _vm.rowData },
        on: {
          cancel: function ($event) {
            _vm.dialogVisible5 = false
          },
          ok: _vm.ok2,
        },
      }),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }