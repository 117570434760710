var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "el-dialog",
    {
      directives: [{ name: "drag", rawName: "v-drag" }],
      attrs: {
        title: "新增",
        visible: _vm.dialogVisible,
        width: "800px",
        "show-close": true,
        "append-to-body": true,
        "close-on-click-modal": false,
        "close-on-press-escape": false,
        "before-close": _vm.cancel,
      },
      on: {
        "update:visible": function ($event) {
          _vm.dialogVisible = $event
        },
      },
    },
    [
      _c(
        "div",
        { staticClass: "container" },
        [
          _vm.form.type !== 2
            ? _c("TabStep", {
                staticClass: "tabstep",
                attrs: { active: _vm.active },
              })
            : _vm._e(),
          _c(
            "el-form",
            {
              directives: [
                {
                  name: "show",
                  rawName: "v-show",
                  value: _vm.active === 1,
                  expression: "active === 1",
                },
              ],
              ref: "ruleForm",
              attrs: {
                model: _vm.form,
                rules: _vm.rules,
                "label-position": "top",
              },
            },
            [
              _c(
                "el-row",
                [
                  _c(
                    "el-col",
                    { attrs: { span: 12 } },
                    [
                      _c(
                        "el-form-item",
                        { attrs: { label: "供应商", prop: "supplierId" } },
                        [
                          _c(
                            "el-select",
                            {
                              staticStyle: { width: "320px" },
                              attrs: {
                                disabled:
                                  _vm.mergeDefrayObj.supplierId &&
                                  _vm.addType !== "add",
                                placeholder: "请选择供应商",
                                filterable: "",
                              },
                              on: { change: _vm.supplierChange },
                              model: {
                                value: _vm.form.supplierId,
                                callback: function ($$v) {
                                  _vm.$set(_vm.form, "supplierId", $$v)
                                },
                                expression: "form.supplierId",
                              },
                            },
                            _vm._l(_vm.supplierOptions, function (item) {
                              return _c("el-option", {
                                key: item.value,
                                attrs: { label: item.label, value: item.value },
                              })
                            }),
                            1
                          ),
                        ],
                        1
                      ),
                    ],
                    1
                  ),
                  _c(
                    "el-col",
                    { attrs: { span: 12 } },
                    [
                      _c(
                        "el-form-item",
                        { attrs: { label: "申付单类型", prop: "type" } },
                        [
                          _c(
                            "el-select",
                            {
                              staticStyle: { width: "320px" },
                              attrs: {
                                disabled:
                                  _vm.mergeDefrayObj.supplierId &&
                                  _vm.addType !== "add",
                                placeholder: "请选择申付单类型",
                              },
                              model: {
                                value: _vm.form.type,
                                callback: function ($$v) {
                                  _vm.$set(_vm.form, "type", $$v)
                                },
                                expression: "form.type",
                              },
                            },
                            [
                              _c("el-option", {
                                attrs: { label: "清提", value: 0 },
                              }),
                              _c("el-option", {
                                attrs: { label: "派送", value: 1 },
                              }),
                              _c("el-option", {
                                attrs: { label: "派送（预付）", value: 2 },
                              }),
                            ],
                            1
                          ),
                        ],
                        1
                      ),
                    ],
                    1
                  ),
                ],
                1
              ),
              _c(
                "el-row",
                [
                  _c(
                    "el-col",
                    { attrs: { span: 12 } },
                    [
                      _c(
                        "el-form-item",
                        {
                          attrs: {
                            label: "期望支付日期",
                            prop: "expectedPaymentDate",
                          },
                        },
                        [
                          _c("el-date-picker", {
                            staticStyle: { width: "320px" },
                            attrs: { placeholder: "请输入期望支付日期" },
                            model: {
                              value: _vm.form.expectedPaymentDate,
                              callback: function ($$v) {
                                _vm.$set(_vm.form, "expectedPaymentDate", $$v)
                              },
                              expression: "form.expectedPaymentDate",
                            },
                          }),
                        ],
                        1
                      ),
                    ],
                    1
                  ),
                  _c(
                    "el-col",
                    { attrs: { span: 12 } },
                    [
                      _c(
                        "el-form-item",
                        { attrs: { label: "币制选择", prop: "currency" } },
                        [
                          _c("el-input", {
                            staticStyle: { width: "320px" },
                            attrs: { disabled: "", placeholder: "请选择币制" },
                            model: {
                              value: _vm.form.currency,
                              callback: function ($$v) {
                                _vm.$set(_vm.form, "currency", $$v)
                              },
                              expression: "form.currency",
                            },
                          }),
                        ],
                        1
                      ),
                    ],
                    1
                  ),
                ],
                1
              ),
              _c(
                "el-row",
                [
                  _c(
                    "el-col",
                    { attrs: { span: 12 } },
                    [
                      _c(
                        "el-form-item",
                        {
                          attrs: {
                            label: "供应商银行账号",
                            prop: "supplierBankAccountId",
                          },
                        },
                        [
                          _c(
                            "div",
                            {
                              staticClass: "custom_supplier",
                              on: { click: _vm.customSelect },
                            },
                            [
                              !_vm.form.supplierBankAccountId
                                ? _c("div", { staticClass: "placeholder" }, [
                                    _vm._v("请选择供应商银行账号"),
                                  ])
                                : _vm._e(),
                              _vm.form.supplierBankAccountId
                                ? _c("div", { staticClass: "account_box" }, [
                                    _c("div", { staticClass: "account-t" }, [
                                      _c(
                                        "span",
                                        {
                                          class: [
                                            "account_mark",
                                            _vm.accountMark(
                                              _vm.selectAccount.bankName
                                            ),
                                          ],
                                        },
                                        [
                                          _vm._v(
                                            _vm._s(_vm.selectAccount.bankName)
                                          ),
                                        ]
                                      ),
                                      _c(
                                        "span",
                                        { staticClass: "account_no" },
                                        [
                                          _vm._v(
                                            _vm._s(
                                              _vm.selectAccount.accountNumber
                                            )
                                          ),
                                        ]
                                      ),
                                    ]),
                                    _c("div", { staticClass: "account-b" }, [
                                      _vm._v(
                                        _vm._s(_vm.selectAccount.accountName)
                                      ),
                                    ]),
                                  ])
                                : _vm._e(),
                              _c("i", {
                                class: [
                                  "el-icon-arrow-up",
                                  _vm.arrowDown && "arrowDown",
                                ],
                              }),
                            ]
                          ),
                          _c(
                            "el-select",
                            {
                              ref: "supplier",
                              staticClass: "supplier",
                              staticStyle: { width: "320px" },
                              attrs: { placeholder: "请选择供应商银行账号" },
                              on: {
                                "visible-change": (e) => {
                                  !e && (_vm.arrowDown = false)
                                },
                              },
                              model: {
                                value: _vm.form.supplierBankAccountId,
                                callback: function ($$v) {
                                  _vm.$set(
                                    _vm.form,
                                    "supplierBankAccountId",
                                    $$v
                                  )
                                },
                                expression: "form.supplierBankAccountId",
                              },
                            },
                            _vm._l(_vm.bankAccountOptions, function (item) {
                              return _c(
                                "el-option",
                                {
                                  key: item.value,
                                  staticClass: "accountlist-item",
                                  attrs: {
                                    label: item.label,
                                    value: item.value,
                                  },
                                  nativeOn: {
                                    click: function ($event) {
                                      _vm.form.currency = item.currency
                                    },
                                  },
                                },
                                [
                                  _c("div", { staticClass: "account_box" }, [
                                    _c("div", { staticClass: "account-t" }, [
                                      _c(
                                        "span",
                                        {
                                          class: [
                                            "account_mark",
                                            _vm.accountMark(item.bankName),
                                          ],
                                        },
                                        [_vm._v(_vm._s(item.bankName))]
                                      ),
                                      _c(
                                        "span",
                                        { staticClass: "account_no" },
                                        [_vm._v(_vm._s(item.accountNumber))]
                                      ),
                                    ]),
                                    _c("div", { staticClass: "account-b" }, [
                                      _vm._v(_vm._s(item.accountName)),
                                    ]),
                                  ]),
                                ]
                              )
                            }),
                            1
                          ),
                        ],
                        1
                      ),
                    ],
                    1
                  ),
                  _vm.form.type === 2
                    ? _c(
                        "el-col",
                        { attrs: { span: 12 } },
                        [
                          _c(
                            "el-form-item",
                            { attrs: { label: "金额", prop: "totalAmount" } },
                            [
                              _c("el-input", {
                                staticStyle: { width: "320px" },
                                attrs: {
                                  placeholder: "请输入金额",
                                  oninput:
                                    "value=value.replace(/^\\.+|[^\\d.]/g,'')",
                                },
                                model: {
                                  value: _vm.form.totalAmount,
                                  callback: function ($$v) {
                                    _vm.$set(_vm.form, "totalAmount", $$v)
                                  },
                                  expression: "form.totalAmount",
                                },
                              }),
                            ],
                            1
                          ),
                        ],
                        1
                      )
                    : _vm._e(),
                ],
                1
              ),
              _c(
                "el-row",
                [
                  _c(
                    "el-col",
                    { attrs: { span: 24 } },
                    [
                      _c(
                        "el-form-item",
                        { attrs: { label: "备注", prop: "remark" } },
                        [
                          _c("el-input", {
                            attrs: {
                              type: "textarea",
                              autosize: { minRows: 3, maxRows: 3 },
                              resize: "none",
                              placeholder: "请输入备注内容",
                            },
                            model: {
                              value: _vm.form.remark,
                              callback: function ($$v) {
                                _vm.$set(_vm.form, "remark", $$v)
                              },
                              expression: "form.remark",
                            },
                          }),
                        ],
                        1
                      ),
                    ],
                    1
                  ),
                ],
                1
              ),
            ],
            1
          ),
          _c(
            "div",
            {
              directives: [
                {
                  name: "show",
                  rawName: "v-show",
                  value: _vm.active === 2,
                  expression: "active === 2",
                },
              ],
            },
            [
              _c(
                "div",
                { staticClass: "header" },
                [
                  _c(
                    "el-upload",
                    {
                      attrs: {
                        action: "#",
                        "show-file-list": false,
                        "http-request": _vm.httpRequest,
                      },
                    },
                    [
                      _c("div", { staticClass: "import" }, [
                        _c("img", {
                          attrs: {
                            src: require("@/assets/images/order/upload.png"),
                          },
                        }),
                        _vm._v("导入 "),
                      ]),
                    ]
                  ),
                  _c(
                    "div",
                    { staticClass: "download", on: { click: _vm.download } },
                    [
                      _c("img", {
                        attrs: {
                          src: require("@/assets/images/order/download.png"),
                        },
                      }),
                      _vm._v("下载模板 "),
                    ]
                  ),
                ],
                1
              ),
              _c(
                "div",
                { staticClass: "data" },
                [
                  _c("BaseTable", {
                    attrs: {
                      height: "399",
                      tableData: _vm.tableData,
                      loading: _vm.loading,
                      isPager: false,
                      border: true,
                    },
                    scopedSlots: _vm._u([
                      {
                        key: "invoiceNumber",
                        fn: function (row) {
                          return [
                            !row.scope.isSuccess
                              ? _c(
                                  "span",
                                  [
                                    _c(
                                      "el-tooltip",
                                      {
                                        staticClass: "item",
                                        attrs: {
                                          effect: "dark",
                                          content: row.scope.errorMessage,
                                          placement: "bottom",
                                        },
                                      },
                                      [
                                        _c("span", [
                                          _c("i", {
                                            staticClass:
                                              "icon el-icon-warning-outline",
                                          }),
                                        ]),
                                      ]
                                    ),
                                    _vm._v(
                                      " " +
                                        _vm._s(row.scope.invoiceNumber) +
                                        " "
                                    ),
                                  ],
                                  1
                                )
                              : _c("span", [
                                  _vm._v(_vm._s(row.scope.invoiceNumber)),
                                ]),
                          ]
                        },
                      },
                    ]),
                  }),
                ],
                1
              ),
            ]
          ),
        ],
        1
      ),
      _c(
        "span",
        { staticClass: "footers", attrs: { slot: "footer" }, slot: "footer" },
        [
          _c(
            "div",
            { staticClass: "cancel flex_c_c", on: { click: _vm.cancel } },
            [_vm._v("取 消")]
          ),
          _vm.active !== 1
            ? _c(
                "div",
                { staticClass: "ok flex_c_c", on: { click: _vm.last } },
                [_vm._v("上一步")]
              )
            : _vm._e(),
          _c(
            "div",
            {
              staticClass: "ok flex_c_c",
              on: {
                click: function ($event) {
                  return _vm.ok("ruleForm")
                },
              },
            },
            [
              _vm._v(
                " " +
                  _vm._s(
                    _vm.active === 1 && _vm.form.type !== 2 ? "下一步" : "确定"
                  ) +
                  " "
              ),
            ]
          ),
        ]
      ),
    ]
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }