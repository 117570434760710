<template>
  <el-dialog
    v-drag
    title="财务审批"
    :visible.sync="dialogVisible"
    width="800px"
    :close-on-click-modal="false"
    :close-on-press-escape="false"
    :before-close="cancel"
    top="2%"
  >
    <div class="content">
      <!-- <div class="preview">
        <div class="preview-left">
          <img src="@/assets/images/pdf/pdf.png" />
          <div class="preview-info">
            <p>付款申请单.pdf</p>
          </div>
        </div>
        <div class="preview-right" @click="preview">
          <img src="@/assets/images/system/look.png" />预览
        </div>
      </div> -->
      <el-form
        ref="form"
        class="form"
        :model="form"
        :rules="rules"
        label-position="top"
      >
        <el-row>
          <el-col :span="24">
            <el-form-item prop="reviewComments" label="审批意见">
              <el-input
                v-model="form.reviewComments"
                type="textarea"
                resize="none"
                placeholder="请输入"
                :autosize="{ minRows: 1, maxRows: 3}"
              />
            </el-form-item>
          </el-col>
        </el-row>
        <el-row>
          <el-col :span="12">
            <el-form-item prop="totalAmount" label="支付金额" style="width: 320px">
              <el-input
                v-model="form.totalAmount"
                placeholder="请输入"
                style="width: 100%"
                oninput="value=value.replace(/[^0-9.]/g,'')"
              >
                <span slot="suffix" class="suffix">{{ currency }}</span>
              </el-input>
            </el-form-item>
          </el-col>
          <el-col :span="12">
            <el-form-item prop="paymentTime" label="支付日期" style="width: 320px">
              <el-date-picker
                v-model="form.paymentTime"
                type="date"
                style="width: 100%"
                placeholder="请选择日期"
              >
              </el-date-picker>
            </el-form-item>
          </el-col>
        </el-row>
        <el-row>
          <el-col :span="12">
            <el-form-item label="供应商" style="width: 320px">
              <el-input v-model="rowData.supplierName" disabled></el-input>
            </el-form-item>
          </el-col>
          <el-col :span="12">
            <el-form-item prop="fileList" label="上传支付水单" style="width: 320px">
              <el-upload
                class="upload-demo"
                action="#"
                :show-file-list="false"
                :http-request="httpRequest"
              >
                <div class="upload flex_c_c">
                  <img src="@/assets/images/bill/upload-1.png" />
                  选择文件上传
                </div>
                <div
                  slot="tip"
                  :style="{
                    display:
                      form.fileList[0] && form.fileList[0].fileName
                        ? 'flex'
                        : 'none',
                  }"
                  class="fileList"
                >
                  <div
                    style="flex: 1; display: flex; align-items: center"
                  >
                    <div class="flex_c_c">
                      <el-progress
                        type="circle"
                        :width="40"
                        :height="40"
                        :stroke-width="4"
                        :percentage="form.fileList.length ? 100 : 0"
                      ></el-progress>
                    </div>
                    <div class="file">
                      <el-tooltip
                        ref="tlp"
                        :content="form.fileList[0]?.fileName"
                        effect="dark"
                        :disabled="!tooltipFlag"
                        placement="bottom"
                      >
                        <p
                          class="fileName"
                          @mouseenter="visibilityChange($event)"
                        >
                          {{ form.fileList[0]?.fileName }}
                        </p>
                      </el-tooltip>
                      <p class="fileSize">
                        {{ form.fileList[0]?.fileSize }} mb
                      </p>
                    </div>
                  </div>
                  <i
                    class="el-icon-delete icon"
                    @click="deleteFile"
                  ></i>
                </div>
              </el-upload>
            </el-form-item>
          </el-col>
        </el-row>
        <el-row>
          <el-col :span="12">
            <el-form-item label="收款账号" style="width: 320px">
              <div class="custom_supplier">
                <div class="account_box">
                  <div class="account-t">
                    <span :class="['account_mark', accountMark(bankAccountInfo.bankName)]">{{ bankAccountInfo.bankName }}</span>
                    <span class="account_no">{{ bankAccountInfo.accountNumber }}</span>
                  </div>
                  <div class="account-b">{{ bankAccountInfo.accountName }}</div>
                </div>
              </div>
            </el-form-item>
          </el-col>
          <el-col :span="12">
            <el-form-item label="支付方式" style="width: 320px" prop="payableType">
              <el-select
                v-model="form.payableType"
                style="width: 100%"
                placeholder="请选择"
              >
                <el-option
                  v-for="item in config.payableTypeOptions"
                  :key="item.value"
                  :label="item.label"
                  :value="item.value"
                >
                </el-option>
              </el-select>
            </el-form-item>
          </el-col>
        </el-row>
        <el-row>
          <div class="form-item">
            <label class="lable">
              <div class="lable-title">
                <span class="red">* </span>选择账单
              </div>
              <el-input
                v-model="search"
                class="search"
                size="small"
                style="width: 200px"
                placeholder="请输入提单号搜索"
              >
                <i
                  slot="suffix"
                  class="el-input__icon el-icon-search"
                ></i>
              </el-input>
            </label>
          </div>
          <el-form-item prop="invoiceIds">
            <BaseTable
              :height="220"
              :tableData="{
                ...tableData,
                columns,
                mutiCheck: true
              }"
              :isPager="false"
              :border="true"
              @handleSelectionChange="handleSelectionChange"
            ></BaseTable>
          </el-form-item>
        </el-row>
      </el-form>
    </div>
    <span slot="footer" class="footers">
      <div class="cancel flex_c_c" @click="cancel">取 消</div>
      <div class="ok flex_c_c" @click="ok('form')">确 定</div>
    </span>
  </el-dialog>
</template>
<script>
const BaseTable = () => import('@/components/table/BaseTable')
import axios from 'axios'
import config from '@/utils/config'
import {
  paymentDefray
} from '@/api/fee'
export default {
  components: { BaseTable },
  props: {
    dialogVisible: {
      type: Boolean,
      default: false
    },
    rowData: {
      type: Object,
      default: () => {}
    },
    bankAccountInfo: {
      type: Object,
      default: () => {}
    },
    selectionItem: {
      type: Array,
      default: () => []
    },
    invoiceArr: {
      type: Array,
      default: () => []
    }
  },
  data() {
    return {
      config,
      form: {
        reviewComments: '',
        totalAmount: '',
        paymentTime: '',
        invoiceIds: [],
        fileList: [],
        payableType: ''
      },
      rules: {
        reviewComments: [
          {
            required: true,
            message: '请输入审批意见',
            trigger: 'blur'
          }
        ],
        totalAmount: [
          {
            required: true,
            message: '请输入支付金额',
            trigger: 'blur'
          }
        ],
        paymentTime: [
          {
            required: true,
            message: '请选择支付日期',
            trigger: 'change'
          }
        ],
        invoiceIds: [
          {
            required: true,
            message: '请选择账单',
            trigger: 'change'
          }
        ],
        fileList: [
          {
            required: true,
            message: '请上传水单文件',
            trigger: 'change'
          }
        ],
        payableType: [
          {
            required: true,
            message: '请选择付款方式',
            trigger: 'change'
          }
        ]
      },
      tooltipFlag: false,
      currency: '',
      tableData: {
        fileds: [
          {
            filed: 'invoiceNumber',
            label: '发票号',
            width: '210',
            resizable: true,
            isCopy: true
          },
          {
            filed: 'blNo',
            label: '提单号码',
            width: '210',
            resizable: true,
            isCopy: true
          },
          {
            filed: 'amount',
            label: '金额',
            width: '100',
            resizable: true
          },
          {
            filed: 'currency',
            label: '币制',
            width: '80',
            resizable: true
          }
        ],
        columns: []
      },
      search: ''
    }
  },
  computed: {
    columns() {
      let arr = []
      if (!this.search) {
        arr = this.invoiceArr
      } else {
        this.invoiceArr.forEach((item) => {
          if (item.blNo?.indexOf(this.search.toUpperCase()) > -1) {
            arr.push(item)
          }
        })
      }
      return arr
    }
  },
  methods: {
    ok(formName) {
      if (!this.rowData.isReviewPermission) {
        this.$message.warning('当前用户没有申付权限')
        return
      }
      this.$refs[formName].validate(async(valid) => {
        if (valid) {
          try {
            const formdata = new FormData()
            formdata.set('input.reviewComments', this.form.reviewComments)
            this.form.totalAmount &&
              formdata.set(
                'input.totalAmount',
                this.form.totalAmount.toString().replace(/[^0-9.]/g, '')
              )
            this.form.paymentTime &&
              formdata.set(
                'input.paymentTime',
                this.formatTime(this.form.paymentTime)
              )
            this.form.invoiceIds.length &&
              this.form.invoiceIds.forEach((a, i) => {
                formdata.set(`input.invoiceIds[${i}]`, a.id)
              })
            this.form.fileList.length &&
              formdata.set('file', this.form.fileList[0].file)
            const result = await paymentDefray(formdata)
            if (result.data.success) {
              this.$emit('ok')
              this.$refs[formName].resetFields()
              this.$message.success(result.data.errorMessage)
            } else {
              this.$message.error(result.data.errorMessage)
            }
          } catch (error) {
            console.error(error)
          }
        } else {
          return false
        }
      })
    },
    cancel() {
      this.$refs['form'].resetFields()
      this.$emit('cancel')
    },
    async preview() {
      const res = await axios({
        url: `/backEnd/api/5KXQFWYH/Defray/getDefrayPDF?defrayId=${this.rowData.id}`,
        method: 'get',
        responseType: 'blob'
      })
      const filestream = res.data // 返回的文件流
      const blob = new Blob([filestream], {
        type: 'application/pdf'
      })
      const url = window.URL.createObjectURL(blob)
      window.open(url)
    },
    httpRequest({ file }) {
      const suffix = file.name.slice(
        file.name.lastIndexOf('.') + 1,
        file.name.length
      )
      if (!(suffix === 'png' || suffix === 'jpg' || suffix === 'jpeg')) {
        this.$message.warning('请上传图片格式文件')
        return false
      }
      this.form.fileList = [
        {
          file,
          fileName: file.name,
          fileSize: (file.size / 1024 / 1024).toFixed(2)
        }
      ]
    },
    deleteFile() {
      this.form.fileList = []
    },
    visibilityChange(event) {
      const ev = event.target
      const evWeight = ev.scrollWidth // 文本的实际宽度
      const contentWeight = this.$refs.tlp.$el.parentNode.clientWidth // 文本容器宽度(父节点)
      if (evWeight > contentWeight) {
        // 文本宽度 > 实际内容宽度  -->内容溢出,则显示悬浮
        this.tooltipFlag = true
      } else {
        // 否则为未溢出，不显示悬浮
        this.tooltipFlag = false
      }
    },
    accountMark(v) {
      const mark1 = ['中国银行', '招商银行', '工商银行', '中信银行']
      const mark2 = ['中国建设银行', '交通银行', '美国银行']
      const mark3 = ['农业银行']
      const mark4 = ['汇丰银行']
      const mark5 = ['花旗银行']
      if (mark1.includes(v)) {
        return 'account_mark1'
      }
      if (mark2.includes(v)) {
        return 'account_mark2'
      }
      if (mark3.includes(v)) {
        return 'account_mark3'
      }
      if (mark4.includes(v)) {
        return 'account_mark4'
      }
      if (mark5.includes(v)) {
        return 'account_mark5'
      }
      if (!(mark1.includes(v) && mark2.includes(v) && mark3.includes(v) && mark4.includes(v) && mark5.includes(v))) {
        return 'account_mark1'
      }
    },
    handleSelectionChange(v) {
      this.form.invoiceIds = v
    },
    formatTime(data) {
      const dtime = new Date(data)
      const year = dtime.getFullYear()
      let month = dtime.getMonth() + 1
      if (month < 10) {
        month = '0' + month
      }
      let day = dtime.getDate()
      if (day < 10) {
        day = '0' + day
      }
      return year + '-' + month + '-' + day
    }
  }
}
</script>
<style lang="less" scoped>
.content {
  width: calc(100% - 80px);
  margin: 0 auto;
  .preview {
    display: flex;
    justify-content: space-between;
    align-items: center;
    width: 320px;
    height: 64px;
    background: #ffffff;
    box-shadow: 0px 0px 6px 1px rgba(0,0,0,0.1);
    border-radius: 6px 6px 6px 6px;
    padding: 12px 20px 12px 10px;
    box-sizing: border-box;
    margin-bottom: 15px;
    .preview-left {
      display: flex;
      img {
        width: 40px;
        height: 40px;
      }
    }
    .preview-info {
      display: flex;
      flex-direction: column;
      justify-content: space-around;
      margin-left: 10px;
      p {
        font-size: 14px;
        font-weight: 400;
        color: #666666;
      }
    }
    .preview-right {
      font-size: 14px;
      font-weight: 400;
      color: #2395f3;
      cursor: pointer;
    }
  }
  /deep/ .el-col:nth-child(2n) {
    display: flex;
    justify-content: flex-end;
  }
  .suffix {
    font-size: 14px;
    font-weight: 400;
    color: #666666;
    margin-right: 4px;
  }
  .upload-demo {
    position: relative;
    .upload {
      width: 140px;
      height: 40px;
      border-radius: 4px 4px 4px 4px;
      border: 1px solid #2395f3;
      font-size: 14px;
      font-family: Microsoft YaHei-Regular, Microsoft YaHei;
      font-weight: 400;
      color: #2395f3;
      box-sizing: border-box;
    }
    .file {
      margin-left: 10px;
    }
    .fileList {
      position: absolute;
      align-items: center;
      width: 320px;
      height: 64px;
      background: #FFFFFF;
      box-shadow: 0px 0px 6px 1px rgba(0,0,0,0.1);
      border-radius: 6px 6px 6px 6px;
      padding: 0 10px;
      box-sizing: border-box;
      z-index: 2;
      .icon {
        font-size: 18px;
        color: #2395f3;
        cursor: pointer;
      }
    }
    .fileName {
      width: 220px;
      font-size: 14px;
      font-family: Microsoft YaHei-Regular, Microsoft YaHei;
      font-weight: 400;
      color: #666666;
      overflow: hidden;
      white-space: nowrap;
      text-overflow: ellipsis;
      line-height: normal;
    }
    .fileSize {
      height: 16px;
      font-size: 12px;
      font-family: Microsoft YaHei-Regular, Microsoft YaHei;
      font-weight: 400;
      color: #999999;
      line-height: normal;
    }
  }
  .custom_supplier {
    width: 320px;
    height: 60px;
    background: #F5F7FA;
    border-radius: 4px 4px 4px 4px;
    border: 1px solid #E4E7ED;
    box-sizing: border-box;
    padding: 10px;
    .account-t {
      line-height: normal;
    }
    .account-b {
      height: 16px;
      font-size: 12px;
      font-family: Microsoft YaHei-Regular, Microsoft YaHei;
      font-weight: 400;
      color: #999999;
      margin-top: 5px;
      line-height: normal;
    }

    .account_mark {
      display: inline-block;
      width: auto;
      height: 20px;
      border-radius: 2px 2px 2px 2px;
      padding: 2px 6px;
      box-sizing: border-box;
      margin-right: 5px;
      font-size: 12px;
      font-family: Microsoft YaHei-Regular, Microsoft YaHei;
      font-weight: 400;
      line-height: normal;
    }
    .account_mark1 {
      background: rgba(254, 69, 61, .1);
      color: #FE453D;
    }
    .account_mark2 {
      background: rgba(35, 149, 243, .1);
      color: #2395F3;
    }
    .account_mark3 {
      background: rgba(53, 186, 131, .1);
      color: #35BA83;
    }
    .account_mark4 {
      background: rgba(255, 116, 68, .1);
      color: #FF7444;
    }
    .account_mark5 {
      background: rgba(157, 103, 254, .1);
      color: #9D67FE;
    }
  }
  /deep/ table tr {
    line-height: normal;
  }
  .form-item {
    position: relative;
    .lable {
      display: flex;
      justify-content: space-between;
      width: 100%;
      height: 30px;
      font-size: 14px;
      font-weight: 400;
      color: #666666;
      line-height: 20px;
      margin-bottom: 10px;
    }
    .lable-title {
      align-self: flex-end;
    }
    .red {
      color: rgba(255, 116, 68, 1);
    }
  }
  .search {
    /deep/ .el-input__suffix {
      display: flex;
      align-items: center;
      justify-content: center;
      top: 0;
      right: 0;
      width: 30px;
      height: 30px;
      border-left: 1px solid #eeeeee;
      box-sizing: border-box;
      color: #666666;
      font-size: 15px;
      overflow: hidden;
      cursor: pointer;
    }
  }
}
.footers {
  display: flex;
  justify-content: center;
  .cancel {
    width: 120px;
    height: 40px;
    background: #EEEEEE;
    border-radius: 4px 4px 4px 4px;
    font-size: 14px;
    font-family: Microsoft YaHei-Regular, Microsoft YaHei;
    font-weight: 400;
    color: #999999;
    margin-right: 16px;
    cursor: pointer;
  }
  .ok {
    width: 120px;
    height: 40px;
    background: #2395f3;
    border-radius: 4px 4px 4px 4px;
    font-size: 14px;
    cursor: pointer;
    font-family: Microsoft YaHei-Regular, Microsoft YaHei;
    font-weight: 400;
    color: #ffffff;
  }
}
</style>
