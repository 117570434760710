var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c("div", { staticClass: "tab-content" }, [
    _c(
      "div",
      {
        staticClass: "step-content flex_c_c",
        style: { width: `${_vm.width}px` },
      },
      [
        _c(
          "div",
          { class: { active: _vm.active > 0 } },
          [
            _c("svg-icon", { attrs: { className: "icon", iconClass: "one" } }),
            _vm._v(" 选供应商 "),
          ],
          1
        ),
        _c("svg-icon", {
          class: { active: _vm.active > 1 },
          attrs: { className: "icon arrow", iconClass: "arrow" },
        }),
        _c(
          "div",
          { class: { active: _vm.active > 1 } },
          [
            _c("svg-icon", { attrs: { className: "icon", iconClass: "two" } }),
            _vm._v(" 核对明细 "),
          ],
          1
        ),
      ],
      1
    ),
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }