var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "Drawer",
    {
      attrs: { drawer: _vm.drawer, title: "明细列表", size: 1080 },
      on: { close: _vm.handleClose },
    },
    [
      _c(
        "div",
        { staticClass: "view" },
        [
          _c("BaseTable", {
            attrs: {
              height: _vm.height,
              tableData: _vm.tableData,
              isPager: false,
              loading: _vm.loading,
            },
            scopedSlots: _vm._u([
              {
                key: "isReview",
                fn: function (row) {
                  return [
                    _c(
                      "span",
                      {
                        style: {
                          color: row.scope.isReview ? "#35BA83" : "#FE453D",
                        },
                      },
                      [_vm._v(_vm._s(row.scope.isReview ? "已支付" : "未支付"))]
                    ),
                  ]
                },
              },
            ]),
          }),
        ],
        1
      ),
    ]
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }