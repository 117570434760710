<template>
  <div>
    <Drawer :drawer="drawer" :withHeader="false" :size="600" @close="close">
      <div v-loading="loading" class="view">
        <div class="header">
          <div class="left">
            <span class="title">审批</span>
            <p class="claim-no">申付单号：{{ rowData.defrayNumber }}</p>
          </div>
          <i class="el-icon-close icon" @click="close" />
        </div>
        <div class="progress">
          <!-- 显示返回的数据的 -->
          <div
            v-for="(item, $index) in detailList"
            :key="item.id"
            class="panel"
          >
            <div class="point-content">
              <div v-if="$index" class="top-line acitve"></div>
              <div
                :class="['point', { acitve: $index <= detailList.length }]"
              ></div>
              <div
                v-if="$index + 1 < detailList.length || rowData.status !== 25"
                class="bottom-line"
                :class="{'acitve': $index + 1 < detailList.length || rowData.isReviewPermission }"
              ></div>
            </div>
            <div class="content">
              <img
                v-if="
                  $index < detailList.length &&
                    item.isApproved &&
                    !item.isUnfold &&
                    item.reviewType !== 20
                "
                class="approved"
                src="@/assets/images/const/isApproved.png"
              />
              <img
                v-else-if="
                  $index < detailList.length &&
                    !item.isApproved &&
                    !item.isUnfold
                "
                class="approved"
                src="@/assets/images/const/noApproved.png"
              />
              <img
                v-else-if="
                  !item.isUnfold &&
                    item.reviewType === 20 &&
                    $index !== detailList.length - 1
                "
                class="approved"
                src="@/assets/images/const/examine_section.png"
              />
              <img
                v-else-if="
                  !item.isUnfold &&
                    rowData.status === 25 &&
                    $index === detailList.length - 1
                "
                class="approved"
                src="@/assets/images/const/examine_all.png"
              />
              <div
                v-if="$index < detailList.length"
                class="unfold flex_c_c"
                @click="item.isUnfold = !item.isUnfold"
              >
                <img
                  v-if="!item.isUnfold"
                  src="@/assets/images/const/zhankai.png"
                />
                <img v-else src="@/assets/images/const/shouqi.png" />
                {{ item.isUnfold ? "收起" : "展开" }}
              </div>
              <p class="title">
                {{ item.reviewTypeName }}
                <span
                  v-if="item.isUnfold &&
                    rowData.status === 25 &&
                    $index === detailList.length - 1"
                  class="examine_all"
                >全部审批</span>
              </p>
              <div
                v-if="
                  !rowData.isReviewPermission &&
                    $index === detailList.length - 1 &&
                    item.isUnfold
                "
                class="preview"
              >
                <div class="preview-left">
                  <img src="@/assets/images/pdf/pdf.png" />
                  <div class="preview-info">
                    <p>付款申请单.pdf</p>
                  </div>
                </div>
                <div class="preview-right" @click="preview">
                  <img src="@/assets/images/system/look.png" />预览
                </div>
              </div>
              <div
                v-if="item.reviewType === 20 && item.isUnfold && detailList[$index].isApproved"
                class="preview"
              >
                <div class="preview-left">
                  <img src="@/assets/images/const/png.png" />
                  <div class="preview-info">
                    <p>水单文件.jpg</p>
                  </div>
                </div>
                <div class="preview-right" @click="previewImg(item.previewImg)">
                  <img src="@/assets/images/system/look.png" />预览
                </div>
              </div>
              <template v-if="item.isUnfold">
                <p class="des" style="margin-top: 10px">
                  <span class="des-label">审批人：</span>
                  <span class="des-value">{{
                    detailList[$index].createdName
                  }}</span>
                </p>
                <p class="des">
                  <span class="des-label">审批时间：</span>
                  <span class="des-value">{{
                    detailList[$index].createdOn?.slice(
                      0,
                      detailList[$index].createdOn.length - 3
                    )
                  }}</span>
                </p>
                <p v-if="+detailList[$index].reviewType === 20 && detailList[$index].isApproved" class="des">
                  <span class="des-label">支付金额：</span>
                  <span
                    class="des-value amount"
                  >{{ detailList[$index].totalAmount }} USD</span
                  >
                </p>
                <p v-if="+detailList[$index].reviewType === 20 && detailList[$index].isApproved" class="des">
                  <span class="des-label">支付时间：</span>
                  <span class="des-value">{{
                    detailList[$index].paymentTime?.slice(
                      0,
                      detailList[$index].paymentTime.length - 9
                    )
                  }}</span>
                </p>
                <p v-if="+detailList[$index].reviewType === 20 && detailList[$index].isApproved" class="des">
                  <span class="des-label">供应商：</span>
                  <span class="des-value">{{ rowData.supplierName }}</span>
                </p>
                <p v-if="+detailList[$index].reviewType === 20 && detailList[$index].isApproved" class="des">
                  <span class="des-label bankAccount_label">收款账号：</span>
                  <span class="des-value bankAccount">
                    <div class="account-t">
                      <span :class="['account_mark', accountMark(item.bankName)]">{{ item.bankName }}</span>
                      <span class="account_no">{{ item.bankAccountNumber }}</span>
                    </div>
                    <div class="account-b">{{ item.bankAccountName }}</div>
                  </span>
                </p>
                <p class="des">
                  <span class="des-label">审批方式：</span>
                  <span
                    class="des-value"
                    :style="{
                      color: detailList[$index].isApproved
                        ? '#35BA83'
                        : '#FE453D',
                    }"
                  >{{ detailList[$index].isApproved ? "通过" : "驳回" }}</span
                  >
                </p>
                <p class="des">
                  <span class="des-label">审批意见：</span>
                  <span class="des-value">{{
                    detailList[$index].reviewComments
                  }}</span>
                </p>
                <p class="des">
                  <span class="des-label">支付类型：</span>
                  <span class="des-value">{{
                    detailList[$index].payableTypeName
                  }}</span>
                </p>
                <div v-if="+detailList[$index].reviewType === 20 && +detailList[$index].defrayInvoice.length" class="des">
                  <div class="payment_bill">
                    <span class="des-label">支付账单：</span>
                    <span
                      v-if="item.reviewType === 20 &&
                        rowData.status === 20"
                      class="not_payment"
                      @click="dialogVisible1 = true"
                    >
                      <i class="el-icon-zoom-in"></i>
                      未支付账单
                    </span>
                  </div>
                  <BaseTable
                    :height="240"
                    :tableData="{
                      ...tableData2,
                      columns: detailList[$index].defrayInvoice
                    }"
                    :headerCellStyle="headerCellStyle"
                    :isPager="false"
                  ></BaseTable>
                </div>
              </template>
              <template v-else>
                <p class="des" style="margin-top: 5px">
                  <span style="margin-right: 10px">{{
                    detailList[$index].createdName
                  }}</span>
                  <span>审批时间：{{ detailList[$index].createdOn }}</span>
                </p>
              </template>
            </div>
          </div>
          <!--  -->
          <div
            v-if="rowData.isReviewPermission &&
              (detailList[detailList.length - 1]?.reviewType !== 20 ||
              !detailList[detailList.length - 1]?.isApproved)"
            class="panel"
          >
            <div class="point-content">
              <div v-if="detailList.length !== 0" class="top-line acitve"></div>
              <div class="point acitve"></div>
              <div
                v-if="
                  !detailList[detailList.length - 1]?.isApproved ||
                  detailList[detailList.length - 1]?.reviewType !== 15 &&
                  detailList[detailList.length - 1]?.reviewType !== 20
                "
                class="bottom-line"
              ></div>
            </div>
            <div class="content">
              <p class="title">{{ title }}</p>
              <div class="preview">
                <div class="preview-left">
                  <img src="@/assets/images/pdf/pdf.png" />
                  <div class="preview-info">
                    <p>付款申请单.pdf</p>
                  </div>
                </div>
                <div class="preview-right" @click="preview">
                  <img src="@/assets/images/system/look.png" />预览
                </div>
              </div>
              <el-form
                ref="ruleForm"
                class="form"
                :model="form"
                :rules="rules"
                label-position="top"
              >
                <el-form-item prop="isApproved" label="审批方式">
                  <el-radio-group v-model="form.isApproved">
                    <el-radio v-if="detailList[detailList.length - 1]?.reviewType !== 15" :label="true">同意</el-radio>
                    <el-radio
                      :label="false"
                    >驳回</el-radio
                    >
                  </el-radio-group>
                </el-form-item>
                <el-form-item prop="reviewComments" label="审批意见">
                  <el-input
                    v-model="form.reviewComments"
                    type="textarea"
                    resize="none"
                    placeholder="请输入"
                    :autosize="{ minRows: 3, maxRows: 3}"
                  />
                </el-form-item>
              </el-form>
              <div class="footer flex_c_c">
                <div class="cancel flex_c_c" @click="cancel('ruleForm')">
                  取消
                </div>
                <div v-loading="okLoading" class="confirm flex_c_c" @click="ok('ruleForm')">保存</div>
              </div>
            </div>
          </div>
          <div
            v-for="(item, $index) in initList"
            :key="$index + 1"
            class="panel"
          >
            <div class="point-content">
              <div class="top-line"></div>
              <div class="point"></div>
              <div class="bottom-line"></div>
            </div>
            <div class="content">
              <p class="stay" style="color: #999999">{{ item }}</p>
            </div>
          </div>
        </div>
      </div>
    </Drawer>
    <PaymentDetailDialog
      :dialogVisible="dialogVisible1"
      :rowData="rowData"
      :type="1"
      @cancel="dialogVisible1 = false"
    ></PaymentDetailDialog>
  </div>
</template>

<script>
const BaseTable = () => import('@/components/table/BaseTable')
const Drawer = () => import('@/components/drawer/Drawer')
import PaymentDetailDialog from './PaymentDetailDialog'
import {
  getDefrayReviewRecord,
  reviewDefray,
  getDefrayInvoice
} from '@/api/fee'
import axios from 'axios'
export default {
  components: { Drawer, BaseTable, PaymentDetailDialog },
  props: {
    drawer: {
      type: Boolean,
      default: false
    },
    rowData: {
      type: Object,
      default: () => {}
    }
  },
  data() {
    return {
      form: {
        reviewComments: '',
        isApproved: true
      },
      loading: false,
      okLoading: false,
      detailList: [],
      stream: '',
      tableData: {
        fileds: [
          {
            filed: 'invoiceNumber',
            label: '发票号',
            width: '132'
          },
          {
            filed: 'mblNo',
            label: '提单号码',
            width: '132'
          },
          {
            filed: 'amount',
            label: '金额',
            width: '100'
          },
          {
            filed: 'currency',
            label: '币制',
            width: '80'
          }
        ],
        columns: []
      },
      tableData2: {
        fileds: [
          {
            filed: 'invoiceNumber',
            label: '发票号',
            width: '150'
          },
          {
            filed: 'mblNo',
            label: '提单号码',
            width: '150'
          },
          {
            filed: 'amount',
            label: '金额',
            width: '100'
          },
          {
            filed: 'currency',
            label: '币制',
            width: '80'
          }
        ]
      },
      headerCellStyle: {
        background: '#F4FCF9',
        color: '#333333',
        fontWeight: '400'
      },
      selectionItem: [],
      invoiceArr: [],
      search: '',
      dialogVisible1: false
    }
  },
  computed: {
    rules() {
      return {
        reviewComments: [
          {
            required: !this.form.isApproved,
            message: '请输入审批意见',
            trigger: 'blur'
          }
        ],
        totalAmount: [
          {
            required: true,
            message: '请输入支付金额',
            trigger: 'blur'
          }
        ],
        paymentTime: [
          {
            required: true,
            message: '请选择支付日期',
            trigger: 'change'
          }
        ],
        invoiceIds: [
          {
            required: true,
            message: '请选择账单',
            trigger: 'change'
          }
        ],
        fileList: [
          {
            required: true,
            message: '请上传水单文件',
            trigger: 'change'
          }
        ],
        isApproved: [{ required: true, trigger: 'change' }]
      }
    },
    title() {
      let title
      const detailTail = this.detailList[this.detailList.length - 1]
      if (!detailTail?.isApproved) {
        title = '创建人审批'
      } else {
        switch (detailTail?.reviewType) {
          case 0:
            title = '主管审批'
            break
          case 5:
            title = '经理审批'
            break
          case 10:
            title = '总监审批'
            break
          case 15:
            title = '财务审批'
            break
          case 20:
            title = '财务审批'
            break
          default:
            title = '创建人审批'
            break
        }
      }
      return title
    },
    initList() {
      let arr
      const detailTail = this.detailList[this.detailList.length - 1]
      if (!detailTail?.isApproved && !this.rowData.isReviewPermission) {
        arr = ['待创建人审批', '待主管审批', '待经理审批', '待总监审批', '待财务审批']
      } else if (!detailTail?.isApproved && this.rowData.isReviewPermission) {
        arr = ['待主管审批', '待经理审批', '待总监审批', '待财务审批']
      } else if (detailTail?.isApproved && !this.rowData.isReviewPermission) {
        switch (detailTail?.reviewType) {
          case 0:
            arr = ['待主管审批', '待经理审批', '待总监审批', '待财务审批']
            break
          case 5:
            arr = ['待经理审批', '待总监审批', '待财务审批']
            break
          case 10:
            arr = ['待总监审批', '待财务审批']
            break
          case 15:
            arr = ['待财务审批']
            break
          case 20:
            arr = []
            break
          default:
            arr = ['待主管审批', '待经理审批', '待总监审批', '待财务审批']
            break
        }
      } else {
        switch (detailTail?.reviewType) {
          case 0:
            arr = ['待经理审批', '待总监审批', '待财务审批']
            break
          case 5:
            arr = ['待总监审批', '待财务审批']
            break
          case 10:
            arr = ['待财务审批']
            break
          case 15:
            arr = []
            break
          case 20:
            arr = []
            break
          default:
            arr = ['待主管审批', '待经理审批', '待总监审批', '待财务审批']
            break
        }
      }
      return arr
    },
    columns() {
      let arr = []
      if (!this.search) {
        arr = this.invoiceArr
      } else {
        this.invoiceArr.forEach((item) => {
          if (item.mblNo?.indexOf(this.search.toUpperCase()) > -1) {
            arr.push(item)
          }
        })
      }
      return arr
    }
    // disabled() {
    //   const detailTail = this.detailList[this.detailList.length - 1]
    //   return !!(detailTail?.reviewType === 20 && detailTail?.isApproved)
    // }
  },
  watch: {
    drawer(val) {
      if (val) {
        this.rowData.status === 20 && (this.form.isApproved = false)
        this.getDetail()
        this.getDefrayPDF()
      }
    }
  },
  methods: {
    close() {
      this.detailList = []
      this.$emit('close')
    },
    async getDetail() {
      try {
        const result = await getDefrayReviewRecord({
          defrayId: this.rowData.id
        })
        if (result.success) {
          this.loading = false
          const newArr = result.data.map((a) => {
            return {
              ...a,
              isUnfold: false
            }
          })
          this.detailList = newArr
          this.getDefrayBillFile()
          this.getDefrayInvoice()
        } else {
          this.$message.error(result.errorMessage)
        }
      } catch (error) {
        console.error(error)
      }
    },
    async getDefrayInvoice() {
      const reviewType =
        this.detailList[this.detailList.length - 1]?.reviewType
      if (reviewType !== 15 && reviewType !== 20) return
      try {
        getDefrayInvoice({
          defrayId: this.rowData.id
        }).then((res) => {
          if (res.success) {
            this.invoiceArr = res.data
          } else {
            this.$message.error(res.errorMessage)
          }
        })
      } catch (error) {
        console.error(error)
      }
    },
    async getDefrayPDF() {
      const res = await axios({
        url: `/backEnd/api/5KXQFWYH/Defray/getDefrayPDF?defrayId=${this.rowData.id}`,
        method: 'get',
        responseType: 'blob'
      })
      const filestream = res.data // 返回的文件流
      const blob = new Blob([filestream], {
        type: 'application/pdf'
      })
      this.stream = window.URL.createObjectURL(blob)
    },
    async getDefrayBillFile() {
      const promise = this.detailList.map(async(a) => {
        if (a.reviewType === 20) {
          const res = await axios({
            url: `/backEnd/api/5KXQFWYH/Defray/getDefrayBillFile?defrayReviewRecordId=${a.id}`,
            method: 'get',
            responseType: 'blob'
          })
          return new Promise((resolve) => {
            resolve(res)
          })
        }
      })
      Promise.allSettled(promise).then((res) => {
        res.forEach((a, i) => {
          const { value } = a
          if (value) {
            const filestream = value.data // 返回的文件流
            const blob = new Blob([filestream], {
              type: 'image/jpeg'
            })
            this.detailList[i].previewImg = window.URL.createObjectURL(blob)
          }
        })
      })
    },
    cancel(formName) {
      this.$refs[formName].resetFields()
    },
    ok(formName) {
      if (!this.rowData.isReviewPermission) {
        this.$message.warning('当前用户审核权限')
        return
      }
      this.okLoading = true
      this.$refs[formName].validate(async(valid) => {
        if (valid) {
          try {
            const formdata = new FormData()
            formdata.set('input.reviewComments', this.form.reviewComments)
            formdata.set('input.isApproved', this.form.isApproved)
            formdata.set('input.defrayId', this.rowData.id)
            const result = await reviewDefray(formdata)
            this.okLoading = false
            if (result.data.success) {
              this.getDetail()
              this.$emit('ok')
              this.$refs[formName].resetFields()
              this.$message.success(result.data.errorMessage)
            } else {
              this.$message.error(result.data.errorMessage)
            }
          } catch (error) {
            this.okLoading = false
            console.error(error)
          }
        } else {
          this.okLoading = false
          return false
        }
      })
    },
    deleteFile() {
      this.form.fileList = []
    },
    preview() {
      const stream = this.stream
      window.open(stream, '_blank')
    },
    previewImg(v) {
      window.open(v, '_blank')
    },
    accountMark(v) {
      const mark1 = ['中国银行', '招商银行', '工商银行', '中信银行']
      const mark2 = ['中国建设银行', '交通银行', '美国银行']
      const mark3 = ['农业银行']
      const mark4 = ['汇丰银行']
      const mark5 = ['花旗银行']
      if (mark1.includes(v)) {
        return 'account_mark1'
      }
      if (mark2.includes(v)) {
        return 'account_mark2'
      }
      if (mark3.includes(v)) {
        return 'account_mark3'
      }
      if (mark4.includes(v)) {
        return 'account_mark4'
      }
      if (mark5.includes(v)) {
        return 'account_mark5'
      }
      if (!(mark1.includes(v) && mark2.includes(v) && mark3.includes(v) && mark4.includes(v) && mark5.includes(v))) {
        return 'account_mark1'
      }
    }
  }
}
</script>

<style lang="less" scoped>
.view {
  width: 100%;
  height: 100%;
  .header {
    width: 600px;
    height: 60px;
    border-bottom: 1px solid #f7f7f7;
    display: flex;
    align-items: center;
    box-sizing: border-box;
    .left {
      flex: 1;
      display: flex;
      text-indent: 20px;
      align-items: center;
    }
    .title {
      height: 60px;
      font-size: 16px;
      font-family: Microsoft YaHei-Regular, Microsoft YaHei;
      font-weight: 400;
      color: #666666;
      line-height: 60px;
    }
    .claim-no {
      width: auto;
      height: 32px;
      background: #f7f7f7;
      border-radius: 4px 4px 4px 4px;
      font-size: 14px;
      font-family: Microsoft YaHei-Regular, Microsoft YaHei;
      font-weight: 400;
      color: #999999;
      padding: 7px 12px;
      text-indent: 0;
      margin-left: 20px;
      box-sizing: border-box;
    }
    .icon {
      font-size: 20px;
      margin-right: 20px;
      cursor: pointer;
    }
  }
  .form {
    margin-top: 20px;
  }
  .progress {
    height: calc(100vh - 100px);
    overflow-x: hidden;
    overflow-y: auto;
    padding: 0 20px 20px;
    box-sizing: border-box;
  }
  .panel {
    width: 100%;
    height: auto;
    display: flex;
    &:first-child .top-line {
      visibility: hidden;
    }
    &:last-child .bottom-line {
      visibility: hidden;
    }
    & .point-content .point.acitve {
      width: 18px;
      height: 18px;
      background: #ffffff;
      opacity: 1;
      border-radius: 50%;
      position: relative;
      &::after {
        content: " ";
        width: 10px;
        height: 10px;
        background: #2395f3;
        position: absolute;
        border-radius: 50%;
        top: 4px;
        left: 4px;
      }
    }
    .acitve {
      border-color: #2395f3 !important;
    }
    .point-content {
      width: 20px;
      height: auto;
      display: flex;
      align-items: center;
      position: relative;
      .point {
        width: 18px;
        height: 18px;
        background: #ffffff;
        opacity: 1;
        border: 1px solid #cdcdcd;
        border-radius: 50%;
        position: relative;
        &::after {
          content: " ";
          width: 10px;
          height: 10px;
          background: #cdcdcd;
          position: absolute;
          border-radius: 50%;
          top: 4px;
          left: 4px;
        }
        &::before {
          content: " ";
        }
      }
      .bottom-line {
        width: 0px;
        height: calc(50% - 10px);
        border: 1px dashed #cdcdcd;
        position: absolute;
        top: 50%;
        margin-top: 10px;
        left: 50%;
        transform: translateX(-50%);
      }
      .top-line {
        width: 0px;
        height: calc(50% - 10px);
        border: 1px dashed #cdcdcd;
        position: absolute;
        top: 0%;
        left: 50%;
        transform: translateX(-50%);
      }
    }
    .content {
      position: relative;
      display: flex;
      flex-direction: column;
      justify-content: center;
      width: 530px;
      min-height: 80px;
      background: #f7f7f7;
      border-radius: 6px 6px 6px 6px;
      padding: 18px 20px;
      margin-left: 10px;
      margin-top: 10px;
      box-sizing: border-box;
      overflow: hidden;
      font-family: Microsoft YaHei-Regular, Microsoft YaHei;
      .stay {
        font-size: 16px;
        font-family: Microsoft YaHei-Regular, Microsoft YaHei;
        font-weight: 400;
      }
      .des + .des {
        margin-top: 10px;
      }
    }
    .preview {
      display: flex;
      justify-content: space-between;
      align-items: center;
      width: 360px;
      height: 64px;
      background: #ffffff;
      box-shadow: 0px 0px 6px 1px rgba(0, 0, 0, 0.1);
      border-radius: 6px 6px 6px 6px;
      margin-top: 15px;
      padding: 12px 20px 12px 10px;
      box-sizing: border-box;
      .preview-left {
        display: flex;
        img {
          width: 40px;
          height: 40px;
        }
      }
      .preview-info {
        display: flex;
        flex-direction: column;
        justify-content: space-around;
        margin-left: 10px;
        p {
          font-size: 14px;
          font-weight: 400;
          color: #666666;
        }
      }
      .preview-right {
        font-size: 14px;
        font-weight: 400;
        color: #2395f3;
        cursor: pointer;
      }
    }
    .unfold {
      position: absolute;
      right: 20px;
      top: 28px;
      width: 50px;
      height: 24px;
      background: #ffffff;
      border-radius: 4px 4px 4px 4px;
      font-size: 12px;
      font-weight: 400;
      color: #999999;
      cursor: pointer;
    }
    .approved {
      position: absolute;
      right: 80px;
      top: 15px;
      width: 80px;
      height: 50px;
    }
    .title {
      width: auto;
      height: 21px;
      font-size: 16px;
      font-weight: 400;
      color: #333333;
      line-height: 24px;
      .examine_all {
        display: inline-block;
        width: 64px;
        height: 24px;
        background: rgba(53, 186, 131, .1);
        border-radius: 2px 2px 2px 2px;
        font-size: 14px;
        font-family: Microsoft YaHei-Regular, Microsoft YaHei;
        font-weight: 400;
        color: #35BA83;
        text-align: center;
      }
    }
    .des {
      font-size: 14px;
      font-weight: 400;
      color: #999999;
      line-height: 24px;
      word-wrap: break-word;
      word-break: break-all;
      // overflow: hidden;
    }
    .payment_bill {
      display: flex;
      justify-content: space-between;
    }
    .not_payment {
      font-size: 12px;
      font-family: Microsoft YaHei-Regular, Microsoft YaHei;
      font-weight: 400;
      color: #2395F3;
      cursor: pointer;
      i {
        font-size: 14px;
      }
    }
    .des-label {
      display: inline-block;
      width: 70px;
    }
    .des-value {
      font-size: 14px;
      font-weight: 400;
      color: #666666;
    }
    .bankAccount_label {
      position: relative;
      top: -25%;
    }
    .bankAccount {
      display: inline-block;
      width: 285px;
      height: 60px;
      background: #FFFFFF;
      box-shadow: 0px 0px 6px 1px rgba(0,0,0,0.1);
      border-radius: 6px 6px 6px 6px;
      padding: 10px;
      box-sizing: border-box;
    }
    .amount {
      font-weight: bold;
      color: #fe453d;
    }
    &:deep(.el-form-item) {
      margin-bottom: 20px;
    }
    &:deep(.el-form-item__label) {
      line-height: 22px;
    }
    .suffix {
      font-size: 14px;
      font-weight: 400;
      color: #666666;
      margin-right: 4px;
    }
    .el-col:nth-child(2n) {
      display: flex;
      justify-content: flex-end;
    }
    .upload-demo {
      display: flex;
    }
    .upload {
      width: 140px;
      height: 40px;
      border-radius: 4px 4px 4px 4px;
      border: 1px solid #2395f3;
      font-size: 14px;
      font-family: Microsoft YaHei-Regular, Microsoft YaHei;
      font-weight: 400;
      color: #2395f3;
      box-sizing: border-box;
    }
    .file {
      margin-left: 10px;
    }
    .fileList {
      width: auto;
      height: 40px;
      margin-left: 20px;
      display: flex;
      align-items: center;
      .icon {
        font-size: 18px;
        color: #2395f3;
        cursor: pointer;
      }
    }
    .fileName {
      width: 112px;
      font-size: 14px;
      font-family: Microsoft YaHei-Regular, Microsoft YaHei;
      font-weight: 400;
      color: #666666;
      overflow: hidden;
      white-space: nowrap;
      text-overflow: ellipsis;
      line-height: normal;
    }
    .fileSize {
      height: 16px;
      font-size: 12px;
      font-family: Microsoft YaHei-Regular, Microsoft YaHei;
      font-weight: 400;
      color: #999999;
      line-height: normal;
    }
    /deep/ .el-table {
      line-height: normal;
    }
    .form-item {
      position: relative;
      .lable {
        display: flex;
        justify-content: space-between;
        width: 100%;
        height: 30px;
        font-size: 14px;
        font-weight: 400;
        color: #666666;
        line-height: 20px;
        margin-bottom: 10px;
      }
      .lable-title {
        align-self: flex-end;
      }
      .red {
        color: rgba(255, 116, 68, 1);
      }
    }
    .search {
      /deep/ .el-input__suffix {
        display: flex;
        align-items: center;
        justify-content: center;
        top: 0;
        right: 0;
        width: 30px;
        height: 30px;
        border-left: 1px solid #eeeeee;
        box-sizing: border-box;
        color: #666666;
        font-size: 15px;
        overflow: hidden;
        cursor: pointer;
      }
    }
  }
  .footer {
    width: 100%;
    margin-top: 20px;
    & div {
      width: 80px;
      height: 40px;
      border-radius: 4px 4px 4px 4px;
      font-size: 14px;
      font-family: Microsoft YaHei-Regular, Microsoft YaHei;
      font-weight: 400;
      box-sizing: border-box;
      cursor: pointer;
    }
    .cancel {
      background: #ffffff;
      border: 1px solid #eeeeee;
      color: #999999;
      margin-right: 20px;
    }
    .confirm {
      background: #2395f3;
      color: #ffffff;
    }
  }
}

.dialog-contanier {
  width: calc(100% - 80px);
  height: auto;
  margin: 0 auto;
  overflow: hidden;
  display: flex;
  justify-content: center;
  align-items: center;
  img {
    width: auto;
    height: auto;
  }
}
.cancel {
  width: 120px;
  height: 40px;
  background: #eeeeee;
  border-radius: 4px 4px 4px 4px;
  font-size: 14px;
  font-family: Microsoft YaHei-Regular, Microsoft YaHei;
  font-weight: 400;
  color: #999999;
  cursor: pointer;
}

.account-b {
  height: 16px;
  font-size: 12px;
  font-family: Microsoft YaHei-Regular, Microsoft YaHei;
  font-weight: 400;
  color: #999999;
  margin-top: 5px;
  line-height: normal;
}

.account_mark {
  display: inline-block;
  width: auto;
  height: 20px;
  border-radius: 2px 2px 2px 2px;
  padding: 2px 6px;
  box-sizing: border-box;
  margin-right: 5px;
  font-size: 12px;
  font-family: Microsoft YaHei-Regular, Microsoft YaHei;
  font-weight: 400;
  line-height: normal;
}
.account_mark1 {
  background: rgba(254, 69, 61, .1);
  color: #FE453D;
}
.account_mark2 {
  background: rgba(35, 149, 243, .1);
  color: #2395F3;
}
.account_mark3 {
  background: rgba(53, 186, 131, .1);
  color: #35BA83;
}
.account_mark4 {
  background: rgba(255, 116, 68, .1);
  color: #FF7444;
}
.account_mark5 {
  background: rgba(157, 103, 254, .1);
  color: #9D67FE;
}
</style>
