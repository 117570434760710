<template>
  <div class="tab-content">
    <div class="step-content flex_c_c" :style="{'width': `${width}px`}">
      <div :class="{'active': active > 0}">
        <svg-icon className="icon" iconClass="one"></svg-icon>
        选供应商
      </div>
      <svg-icon className="icon arrow" :class="{'active': active > 1}" iconClass="arrow"></svg-icon>
      <div :class="{'active': active > 1}">
        <svg-icon className="icon" iconClass="two"></svg-icon>
        核对明细
      </div>
    </div>
  </div>
</template>

<script>
export default {
  props: {
    tabData: {
      type: Array,
      default: function() {
        return []
      }
    },
    active: {
      type: Number,
      default: 1
    },
    width: {
      type: Number,
      default: 400
    }
  }
}
</script>

<style lang="less" scoped>
.tab-content {
  height: 40px;
}
.step-content {
  height: 40px;
  background: #F7F7F7;
  border-radius: 4px 4px 4px 4px;
  border: 1px solid #EEEEEE;
  margin: 0 auto;
  font-size: 14px;
  font-family: Microsoft YaHei-Regular, Microsoft YaHei;
  font-weight: 400;
  color: #999999;
  box-sizing: border-box;
  .arrow {
      margin: 13px 56px;
  }
  .active {
      color: #2395F3;
  }
}
</style>
