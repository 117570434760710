<template>
  <div class="view-contanier">
    <div class="header">
      <div class="tab">
        <span
          v-for="(item, $index) in tabList"
          :key="item.permissson"
          v-permissson="`${item.permissson}`"
          :class="['tabItem', active === $index ? 'active' : '']"
          @click="changeType($index, item.permissson)"
        >
          {{ item.name }}
        </span>
      </div>
      <div class="opt">
        <div v-if="activeCode === 'QB'&& isActive1 && filterList1.invoiceDate" class="checkDate flex_c_c">
          查询时间：{{ filterList1.invoiceDate[0] + "~" + filterList1.invoiceDate[1] }}
        </div>
        <div v-if="activeCode === 'SQJL'" v-permissson="'payment'" class="payment flex_c_c" @click="payment">
          <img src="@/assets/images/const/payment.png" />支付
        </div>
        <div v-if="activeCode === 'QB'" v-permissson="'merge'" class="merge flex_c_c" @click="merge('merge')">
          <img src="@/assets/images/const/merge.png" />合并申付
        </div>
        <div
          v-if="activeCode === 'QB'"
          v-permissson="'derive'"
          class="derive flex_c_c"
          @click="derive"
        >
          <img src="@/assets/images/const/derive.png" />导出
        </div>
        <div
          v-if="activeCode === 'QB'"
          v-permissson="'add'"
          class="add flex_c_c"
          @click="add('add')"
        >
          <svg-icon className="icon" iconClass="add" />新增
        </div>
        <BaseFilter
          :isActive="filterActive"
          :width="filterWidth"
          @sendFilter="sendFilter"
          @reset="reset"
        >
          <div class="filter">
            <el-form
              v-if="!active"
              ref="form"
              :model="filterList1"
              label-width="104px"
            >
              <el-form-item v-if="!active" label="账单日期">
                <el-date-picker
                  v-model="filterList1.invoiceDate"
                  style="width: 320px"
                  type="daterange"
                  range-separator="至"
                  align="center"
                  start-placeholder="开始日期"
                  end-placeholder="结束日期"
                  value-format="yyyy-MM-dd"
                  unlink-panels
                  :picker-options="pickerOptions"
                >
                </el-date-picker>
              </el-form-item>
              <el-form-item label="申付状态">
                <el-select
                  v-model="filterList1.defrayStatus"
                  style="width: 320px"
                  placeholder="请选择"
                >
                  <el-option
                    v-for="item in claimPayDefrayStatusOption"
                    :key="item.value"
                    :label="item.label"
                    :value="item.value"
                  >
                  </el-option>
                </el-select>
              </el-form-item>
              <el-form-item label="申付类型">
                <el-select
                  v-model="filterList1.type"
                  style="width: 320px"
                  placeholder="请选择"
                >
                  <el-option
                    v-for="item in claimPayDefrayTypeOption"
                    :key="item.value"
                    :label="item.label"
                    :value="item.value"
                  >
                  </el-option>
                </el-select>
              </el-form-item>
              <el-form-item label="供应商">
                <el-select
                  v-model="filterList1.supplierId"
                  style="width: 320px"
                  placeholder="请选择"
                >
                  <el-option
                    v-for="item in supplierOptions"
                    :key="item.value"
                    :label="item.label"
                    :value="item.value"
                  >
                  </el-option>
                </el-select>
              </el-form-item>
              <el-form-item label="发票号/提单号">
                <base-muti-input
                  ref="baseMutiInput1"
                  v-model="filterList1.numbers"
                  style="width: 320px"
                ></base-muti-input>
              </el-form-item>
            </el-form>
            <el-form
              v-else-if="active === 1"
              ref="form"
              :model="filterList2"
              label-width="104px">
              <el-form-item label="申付状态">
                <el-select
                  v-model="filterList2.status"
                  style="width: 320px"
                  placeholder="请选择"
                >
                  <el-option
                    v-for="item in claimPayStatusOption"
                    :key="item.value"
                    :label="item.label"
                    :value="item.value"
                  >
                  </el-option>
                </el-select>
              </el-form-item>
              <el-form-item label="申付类型">
                <el-select
                  v-model="filterList2.type"
                  style="width: 320px"
                  placeholder="请选择"
                >
                  <el-option
                    v-for="item in claimPayDefrayTypeOption"
                    :key="item.value"
                    :label="item.label"
                    :value="item.value"
                  >
                  </el-option>
                </el-select>
              </el-form-item>
              <el-form-item label="供应商">
                <el-select
                  v-model="filterList2.supplierId"
                  style="width: 320px"
                  placeholder="请选择"
                >
                  <el-option
                    v-for="item in supplierOptions"
                    :key="item.value"
                    :label="item.label"
                    :value="item.value"
                  >
                  </el-option>
                </el-select>
              </el-form-item>
              <el-form-item label="发票号/提单号">
                <base-muti-input
                  ref="baseMutiInput2"
                  v-model="filterList2.numbers"
                  style="width: 320px"
                ></base-muti-input>
              </el-form-item>
            </el-form>
            <el-form v-else ref="form" :model="filterList3" label-width="74px">
              <el-form-item label="支付时间">
                <el-date-picker
                  v-model="filterList3.date"
                  style="width: 320px"
                  type="daterange"
                  range-separator="至"
                  align="center"
                  start-placeholder="开始日期"
                  end-placeholder="结束日期"
                  value-format="yyyy-MM-dd"
                  :picker-options="pickerOptions"
                >
                </el-date-picker>
              </el-form-item>
              <el-form-item label="供应商">
                <el-select
                  v-model="filterList3.supplierId"
                  style="width: 320px"
                  placeholder="请选择"
                >
                  <el-option
                    v-for="item in supplierOptions"
                    :key="item.value"
                    :label="item.label"
                    :value="item.value"
                  >
                  </el-option>
                </el-select>
              </el-form-item>

            </el-form>
          </div>
        </BaseFilter>
      </div>
    </div>
    <div class="data">
      <BaseTable
        v-if="height !== 0"
        :key="active"
        :height="height"
        :tableData="{
          ...tableData,
          fileds,
        }"
        :pageIndex="pager.pageIndex"
        :pageSize="pager.pageSize"
        :loading="loading"
        @handlePageChange="handlePageChange"
        @handleSelectionChange="handleSelectionChange"
        @sortChange="sortChange"
      >
        <template slot="status" slot-scope="row">
          <span :class="[`status${row.scope.status}`, 'status']">
            {{ row.scope.statusName }}
          </span>
        </template>
        <template slot="defrayStatus" slot-scope="row">
          <span :class="[`status${row.scope.defrayStatus}`, 'status']">
            {{ row.scope.defrayStatus | formatterDefrayStatus }}
          </span>
        </template>
        <template slot="bankAccountNumber" slot-scope="row">
          <div class="account_box">
            <div class="account-t">
              <span :class="['account_mark', accountMark(row.scope.bankName)]">{{ row.scope.bankName }}</span>
              <span class="account_no">{{ row.scope.bankAccountNumber }}</span>
            </div>
            <div class="account-b">{{ row.scope.bankAccountName }}</div>
          </div>
        </template>
        <template slot="opt" slot-scope="row">
          <div class="table-opt">
            <template v-if="active === 0">
              <span
                v-permissson="'detail'"
                @click="copyWithdetail(row.scope)"
              >详情</span
              >
            </template>
            <template v-if="active === 1">
              <span
                v-permissson="'audit'"
                @click="audit(row.scope)"
              >审批</span
              >
              <span
                v-permissson="'detail'"
                @click="detail(row.scope)"
              >查看明细</span
              >
              <span
                v-permissson="'delete'"
                @click="deleteRow(row.scope)"
              >删除</span
              >
            </template>
            <template v-if="active === 2">
              <span
                v-permissson="'billingDetail'"
                @click="billingDetail(row.scope)"
              >账单明细</span
              >
              <span
                v-permissson="'bankSlip'"
                @click="bankSlip(row.scope)"
              >查看水单</span
              >
            </template>
          </div>
        </template>
        <template slot="typeName" slot-scope="row">
          <div :class="['type', `type${row.scope.type}`]">{{ row.scope.typeName }}</div>
        </template>
        <template #custom-total>
          <div class="custom-total">
            <div style="display: flex;">
              <div class="select">已选 {{ selectionItem.length }} 项</div>
              <div v-if="selectionItem.length" class="total" v-html="totalHtml">
              </div>
            </div>
          </div>
        </template>
      </BaseTable>
    </div>
    <AddClaimPaymentDialog
      :dialogVisible="dialogVisible1"
      :mergeDefrayObj="mergeDefrayObj"
      :addType="addType"
      @cancel="dialogVisible1 = false"
      @ok="ok"
    ></AddClaimPaymentDialog>
    <!-- <FillHeaderTextDialog
      :dialogVisible="dialogVisible2"
      :ids="ids"
      @cancel="dialogVisible2 = false"
      @ok="dialogVisible2 = false"
    ></FillHeaderTextDialog> -->
    <FinancialApprovalDialog
      :dialogVisible="dialogVisible3"
      :rowData="rowData"
      :selectionItem="selectionItem"
      :invoiceArr="invoiceArr"
      :bankAccountInfo="bankAccountInfo"
      @cancel="dialogVisible3 = false"
      @ok="ok"
    ></FinancialApprovalDialog>
    <PaymentDetailDialog
      :dialogVisible="dialogVisible4"
      :rowData="rowData"
      :type="0"
      @cancel="dialogVisible4 = false"
    ></PaymentDetailDialog>
    <ViewDetailDrawer
      :drawer="drawer1"
      :defrayId="rowData.id"
      @close="drawer1 = false"
    ></ViewDetailDrawer>
    <ExamineApproveDrawer
      :drawer="drawer2"
      :rowData="rowData"
      @close="drawer2 = false"
      @ok="ok2"
    ></ExamineApproveDrawer>
    <CopeWithDetailDialog
      :dialogVisible="dialogVisible5"
      :rowData="rowData"
      @cancel="dialogVisible5 = false"
      @ok="ok2"
    ></CopeWithDetailDialog>
  </div>
</template>

<script>
const BaseTable = () => import('@/components/table/BaseTable')
const BaseFilter = () => import('@/components/filter/BaseFilter')
const BaseMutiInput = () => import('@/components/base/BaseMutiInput.vue')
import ViewDetailDrawer from './components/ViewDetailDrawer'
import AddClaimPaymentDialog from './components/AddClaimPaymentDialog'
// import FillHeaderTextDialog from './components/FillHeaderTextDialog'
import ExamineApproveDrawer from './components/ExamineApproveDrawer'
import FinancialApprovalDialog from './components/FinancialApprovalDialog'
import PaymentDetailDialog from './components/PaymentDetailDialog'
import CopeWithDetailDialog from './components/CopeWithDetailDialog'
import { getSupplierDropDownList, getSupplierBankAccount } from '@/api/system'
import config from '@/utils/config'
import axios from 'axios'
import {
  getDefrayPageList,
  getWaitDefrayInvoice,
  deleteDefray,
  getDefrayPaymentPage,
  getNotReviewDefrayInvoice
} from '@/api/fee'
import { permissions, derive } from '@/utils/common'
import { myMixins } from '@/mixins'
export default {
  name: 'ClaimPayment',
  components: {
    BaseTable,
    BaseFilter,
    BaseMutiInput,
    ViewDetailDrawer,
    AddClaimPaymentDialog,
    // FillHeaderTextDialog,
    ExamineApproveDrawer,
    FinancialApprovalDialog,
    PaymentDetailDialog,
    CopeWithDetailDialog
  },
  filters: {
    formatterDefrayStatus(status) {
              const obj = {
                0: '未申付账单查询',
                1: '未支付',
                2: '已支付'
              }
              return obj[status]
            }
  },
  mixins: [myMixins],
  data() {
    return {
        pickerOptions: {
          shortcuts: [{
            text: '最近一周',
            onClick(picker) {
              const end = new Date()
              const start = new Date()
              start.setTime(start.getTime() - 3600 * 1000 * 24 * 7)
              picker.$emit('pick', [start, end])
            }
          }, {
            text: '最近一个月',
            onClick(picker) {
              const end = new Date()
              const start = new Date()
              start.setTime(start.getTime() - 3600 * 1000 * 24 * 30)
              picker.$emit('pick', [start, end])
            }
          }, {
            text: '最近三个月',
            onClick(picker) {
              const end = new Date()
              const start = new Date()
              start.setTime(start.getTime() - 3600 * 1000 * 24 * 90)
              picker.$emit('pick', [start, end])
            }
          }]
        },
      active: 0,
      activeCode: '',
      tableData: {
        columns: [],
        total: 0,
        mutiCheck: true,
        mutiFixed: 'left',
        showIndex: false
      },
      loading: false,
      pager: {
        pageIndex: 1,
        pageSize: 20
      },
      height: 0,
      filterList1: {
        numbers: '',
        supplierId: '',
        defrayStatus: '',
        type: '',
        invoiceDate: ''
      },
      filterList2: {
        numbers: '',
        supplierId: '',
        status: '',
        type: ''
      },
      filterList3: {
        supplierId: '',
        date: ''
      },
      isActive1: false,
      isActive3: false,
      isActive2: false,
      dialogVisible1: false,
      dialogVisible2: false,
      dialogVisible3: false,
      dialogVisible4: false,
      dialogVisible5: false,
      drawer1: false,
      drawer2: false,
      dialogType: 0,
      rowData: {},
      selectionItem: [],
      supplierOptions: [],
      claimPayStatusOption: config.claimPayStatusOption,
      claimPayDefrayStatusOption: config.claimPayDefrayStatusOption,
      claimPayDefrayTypeOption: config.claimPayDefrayTypeOption,
      ids: [],
      tabList: [
        { name: '全部', permissson: 'QB' },
        { name: '申请记录', permissson: 'SQJL' },
        { name: '支付记录', permissson: 'ZFJL' }
      ], // tab按钮权限
      invoiceArr: [],
      bankAccountInfo: {},
      orderObj: {},
      addType: 'add'
    }
  },
  computed: {
    fileds() {
      const obj = {
        0: [
          {
            filed: 'invoiceNumber',
            label: '发票号',
            isCopy: true
          },
          {
            filed: 'blNo',
            label: '提单号',
            isCopy: true
          },
          {
            filed: 'supplierName',
            label: '供应商'
          },
          {
            filed: 'amount',
            label: '总金额',
            formatter: (row) =>
              row.amount
                ? row.amount + ` ${row.currency}`
                : `0 ${row.currency}`
          },
          {
            filed: 'currency',
            label: '币制'
          },
          {
            filed: 'defrayStatus',
            label: '状态',
            isCustom: true,
            formatter: row => {
              const obj = {
                0: '未申付账单查询',
                1: '未支付',
                2: '已支付'
              }
              return obj[row.defrayStatus]
            }
          },
          {
            filed: 'typeName',
            label: '类型',
            isCustom: true
          },
          {
            filed: 'opt',
            label: '操作',
            width: '175',
            fixed: 'right',
            isCustom: true
          }
        ],
        1: [
          {
            filed: 'defrayNumber',
            label: '申付单号',
            width: '200',
            isCopy: true
          },
          {
            filed: 'status',
            label: '状态',
            width: '160',
            isCustom: true,
            isSort: true
          },
          {
            filed: 'typeName',
            label: '申付类型',
            width: '150'
          },
          {
            filed: 'totalAmount',
            label: '申付金额',
            width: '160',
            formatter: (row) =>
              row.totalAmount
                ? row.totalAmount + ` ${row.currency}`
                : `0 ${row.currency}`
          },
          {
            filed: 'supplierName',
            label: '供应商',
            width: '180'
          },
          {
            filed: 'currency',
            label: '币制',
            width: '100'
          },
          {
            filed: 'bankAccountNumber',
            label: '收款账号',
            width: '300',
            isCustom: true
          },
          {
            filed: 'expectedPaymentDate',
            label: '期望支付日期',
            width: '130',
            formatter: (row) =>
              row.expectedPaymentDate
                ? row.expectedPaymentDate.slice(
                    0,
                    row.expectedPaymentDate.length - 9
                  )
                : '',
            isSort: true
          },
          {
            filed: 'paymentTime',
            label: '支付次数',
            width: '100'
          },
          {
            filed: 'remark',
            label: '备注',
            width: '200'
          },
          {
            filed: 'createdName',
            label: '创建人',
            width: '120'
          },
          {
            filed: 'createdOn',
            label: '创建时间',
            width: '200',
            formatter: (row) =>
              row.createdOn
                ? row.createdOn.slice(0, row.createdOn.length - 3)
                : '',
            isSort: true
          },
          {
            filed: 'opt',
            label: '操作',
            width: '175',
            fixed: 'right',
            isCustom: true
          }
        ],
        2: [
          {
            filed: 'supplierName',
            label: '供应商',
            width: '180'
          },
          {
            filed: 'bankAccountNumber',
            label: '收款账号',
            width: '300',
            isCustom: true
          },
          {
            filed: 'totalAmount',
            label: '支付金额',
            width: '160',
            formatter: (row) =>
              row.totalAmount
                ? row.totalAmount + ` ${row.currency}`
                : `0 ${row.currency}`
          },
          {
            filed: 'paymentTime',
            label: '支付时间',
            width: '160',
            formatter: (row) =>
              row.paymentTime ? row.paymentTime.slice(0, row.paymentTime.length - 9) : '',
            isSort: true
          },
          {
            filed: 'createdName',
            label: '审批人',
            width: '120'
          },
          {
            filed: 'createdOn',
            label: '审批时间',
            width: '160',
            formatter: (row) =>
              row.createdOn ? row.createdOn.slice(0, row.createdOn.length - 3) : '',
            isSort: true
          },
          {
            filed: 'reviewComments',
            label: '审批意见',
            width: '390'
          },
          {
            filed: 'opt',
            label: '操作',
            width: '200',
            isCustom: true,
            fixed: 'right'
          }
        ]
      }
      return obj[this.active]
    },
    filterActive() {
      let bool
      switch (this.active) {
        case 0:
          bool = this.isActive1
          break
        case 1:
          bool = this.isActive2
          break
        case 2:
          bool = this.isActive3
          break
      }
      return bool
    },
    totalHtml() {
      const arr = []
      this.selectionItem.forEach(a => {
        const item = arr.find(n => n.currency === a.currency)
        if (!item) {
          arr.push({
            currency: a.currency,
            num: a.totalAmount
          })
        } else {
          item.num += +a.totalAmount
        }
      })
      let str = ' 申付金额：'
      arr.forEach(a => {
        str += `<span style="color: #fe453d;font-weight: bold;"> ${a.num}  ${a.currency}</span>`
      })
      return str
    },
    mergeDefrayObj() {
      const obj = {
        supplierId: parseInt(this.selectionItem[0]?.supplierId || 0, 10),
        type: this.selectionItem[0]?.type,
        tableList: []
      }
      this.selectionItem.forEach(a => {
        obj.tableList.push({
          invoiceNumber: a.invoiceNumber,
          blNo: a.blNo,
          supplierName: a.supplierName,
          amount: a.amount,
          currency: a.currency
        })
      })
      return obj
    },
    filterWidth() {
      if (this.active === 0 || this.active === 1) {
        return 494
      } else {
        return 474
      }
    }
  },
  mounted() {
    this.height = document.querySelector('.data').offsetHeight - 93
    if (!this.$store.state.connect.connect.tabCode) {
      const menuList = this.$store.getters.initRouter
      const menu = permissions(this.$route.meta.code, menuList)
      const codeLits = ['QB', 'SQJL', 'ZFJL']
      const childrenCode = menu.children.map(a => a.code)
      this.active = codeLits.findIndex((a) => childrenCode.includes(a))
      const codeFind = menu.children.find(a => a.code === 'QB').code
      this.activeCode = codeFind || menu.children[0].code
      this.getList(this.pager)
      this.getCommData()
    }
  },
  methods: {
    changeType(type, code) {
      this.active = type
      this.activeCode = code
      !type && (this.tableData.showIndex = false)
      type === 2 && (this.tableData.showIndex = true)
      const param = {
        pageIndex: 1,
        pageSize: this.pager.pageSize
      }
      this.pager = param
      this.selectionItem = []
      this.getList(param)
      // this.initList()
    },
    getCommData() {
      getSupplierDropDownList({}).then((res) => {
        if (res.success) {
          res.data &&
            (this.supplierOptions = res.data.map((a) => {
              return {
                label: `${a.code}-${a.name}`,
                value: a.id
              }
            }))
        } else {
          this.$message.error(res.errorMessage)
        }
      })
    },
    async getList(params) {
      try {
        this.loading = true
        params = {
          ...params,
          queryParam: {
            ...this.orderObj,
            ...this.formatterFilter()
          }
        }
        let res
        // !this.active &&
        this.active === 0 && (res = await getWaitDefrayInvoice(params))
        this.active === 1 && (res = await getDefrayPageList(params))
        this.active === 2 && (res = await getDefrayPaymentPage(params))
        if (res.success) {
          this.loading = false
          this.tableData.columns = res.data.list
          this.tableData.total = res.data.total
        } else {
          this.loading = false
          this.tableData.columns = []
          this.$message.error(res.errorMessage)
        }
      } catch (error) {
        this.loading = false
        console.log(error)
      }
    },
    initList() {
      const param = {
        pageIndex: 1,
        pageSize: 20
      }
      this.pager = param
      this.getList(param)
    },
    formatterFilter() {
      const arr = [
        {
          numbers:
            this.filterList1.numbers.length > 0
              ? this.filterList1.numbers
              : undefined,
          supplierId:
            this.filterList1.supplierId !== ''
              ? this.filterList1.supplierId
              : undefined,
          defrayStatus:
            this.filterList1.defrayStatus !== ''
              ? this.filterList1.defrayStatus
              : undefined,
          type:
            this.filterList1.type !== ''
              ? this.filterList1.type
              : undefined,
          invoiceBeginDate:
            this.filterList1.invoiceDate !== ''
              ? this.filterList1.invoiceDate[0]
              : undefined,
          invoiceEndDate:
            this.filterList1.invoiceDate !== ''
              ? this.filterList1.invoiceDate[1]
              : undefined
        },
        {
          numbers:
            this.filterList2.numbers.length > 0
              ? this.filterList2.numbers
              : undefined,
          supplierId:
            this.filterList2.supplierId !== ''
              ? this.filterList2.supplierId
              : undefined,
          status:
            this.filterList2.status !== ''
              ? this.filterList2.status
              : undefined,
          type:
            this.filterList2.type !== '' ? this.filterList2.type : undefined
        },
        {
          supplierId:
            this.filterList3.supplierId !== ''
              ? this.filterList3.supplierId
              : undefined,
          startTime:
            this.filterList3.date !== ''
              ? this.filterList3.date[0]
              : undefined,
          endDate:
            this.filterList3.date !== ''
              ? this.filterList3.date[1]
              : undefined
        }
      ]
      return arr[this.active]
    },
    sendFilter() {
      switch (this.active) {
        case 0:
          if (
            this.filterList1.numbers.length !== 0 ||
            this.filterList1.invoiceDate !== '' ||
            this.filterList1.defrayStatus !== '' ||
            this.filterList1.type !== '' ||
            this.filterList1.supplierId !== ''
          ) {
            this.isActive1 = true
          } else {
            this.isActive1 = false
          }
          break
        case 1:
          if (
            this.filterList2.numbers.length !== 0 ||
            this.filterList2.type !== '' ||
            this.filterList2.status !== '' ||
            this.filterList2.supplierId !== ''
          ) {
            this.isActive2 = true
          } else {
            this.isActive2 = false
          }
          break
        case 2:
          if (
            this.filterList3.supplierId !== '' ||
            this.filterList3.date !== ''
          ) {
            this.isActive3 = true
          } else {
            this.isActive3 = false
          }
          break
      }
      const params = {
        pageIndex: 1,
        pageSize: 20
      }
      this.pager = params
      this.getList(params)
    },
    reset() {
      if (!this.active) {
        this.filterList1 = {
          numbers: '',
          supplierId: '',
          defrayStatus: '',
          type: '',
          invoiceDate: ''
        }
        this.$refs.baseMutiInput1.reset()
        this.isActive1 = false
      }
      if (this.active === 1) {
        this.filterList2 = {
          numbers: '',
          supplierId: '',
          status: '',
          type: ''
        }
        this.$refs.baseMutiInput2.reset()
        this.isActive2 = false
      }
      if (this.active === 2) {
        this.filterList3 = {
          supplierId: '',
          date: ''
        }
        this.isActive3 = false
      }
    },
    handlePageChange(page) {
      this.pager = page
      this.getList(page)
    },
    sortChange(orderObj) {
      if (orderObj.order) {
        this.orderObj = {
          sortField: orderObj.prop,
          sortBy: orderObj.order === 'asc' ? 0 : 1
        }
      } else {
        this.orderObj = {}
      }
      this.getList(this.pager)
    },
    handleSelectionChange(items) {
      this.selectionItem = items
    },
    ok() {
      this.dialogVisible1 = false
      this.dialogVisible3 = false
      this.initList()
    },
    ok2() {
      this.drawer2 = false
      this.getList(this.pager)
    },
    // 审批
    audit(row) {
      this.drawer2 = true
      this.rowData = row
    },
    // 查看明细
    detail(row) {
      this.drawer1 = true
      this.rowData = row
    },
    // 删除
    async deleteRow(row) {
      try {
        const result = await deleteDefray({ defrayId: row.id })
        if (result.success) {
          this.getList(this.pager)
          this.$message.success(result.errorMessage)
        } else {
          this.$message.error(result.errorMessage)
        }
      } catch (error) {
        console.error(error)
      }
    },
    merge(type) {
      // if (this.selectionItem.length < 2) {
      //   this.$message.warning('请选择合并行，最少选择两条')
      //   return
      // }
      let defrayStatusFlag = true
      const supplyArr = []
      const typeNameArr = []
      this.selectionItem.forEach(a => {
        if (a.defrayStatus !== 0) {
          defrayStatusFlag = false
        }
        if (supplyArr.indexOf(a.supplierName) === -1) {
          supplyArr.push(a.supplierName)
        }
        if (typeNameArr.indexOf(a.statusName) === -1) {
          typeNameArr.push(a.statusName)
        }
      })
      if (!defrayStatusFlag) {
        this.$message.warning('数据中包含不是未审核账单')
        return
      }
      if (supplyArr.length > 1) {
        this.$message.warning('数据中包含一个以上的供应商')
        return
      }
      if (supplyArr.length > 1) {
        this.$message.warning('数据中包含一个以上的账单类型')
        return
      }
      this.addType = type
      this.dialogVisible1 = true
      // const newList = []
      // for (const a of this.selectionItem) {
      //   if (newList.find(b => b.createdName === a.createdName)) {
      //     continue
      //   }
      //   newList.push(a)
      // }
      // if (newList.length !== 1) {
      //   this.$message.warning('合并行创建人必须相同')
      //   return
      // }
      // if (this.selectionItem.some(a => a.status < 15)) {
      //   this.$message.warning('合并行需要总监审核之后才能合并')
      //   return
      // }
      // this.ids = this.selectionItem.map(a => a.id)
      // this.dialogVisible2 = true
    },
    derive() {
      if (this.selectionItem.length === 0) {
        this.$message.warning('请选择导出行')
        return
      }
      if (this.selectionItem.length > 1) {
        this.$message.warning('导出只能选择一条')
        return
      }
      const params = {
        defrayId: this.selectionItem[0].id
      }
      derive('Defray/exportDefray', 'get', params)
    },
    async payment() {
      if (!this.selectionItem.length) {
        this.$message.warning('请先勾选数据行')
        return
      }
      let flag = true
      // const firstId = this.selectionItem[0].supplierId
      this.selectionItem.forEach(a => {
        if (a.status !== 20) {
          flag = false
        }
      })
      try {
        const ids = this.selectionItem.map(a => a.id)
        const res = await getNotReviewDefrayInvoice(ids)
        if (res.success) {
          const { currency, supplierId, supplierBankAccountId, defrayInvoices } = res.data
          this.currency = currency
          const supp = await getSupplierBankAccount({ supplierId })
          this.bankAccountInfo = supp.filter(a => a.id === supplierBankAccountId)[0]
          this.invoiceArr = defrayInvoices
        } else {
          flag = false
          this.$message.error(res.errorMessage)
        }
      } catch (error) {
        console.error(error)
      }
      if (flag) {
        this.rowData = this.selectionItem[0]
        this.dialogVisible3 = true
      }
    },
    accountMark(v) {
      const mark1 = ['中国银行', '招商银行', '工商银行', '中信银行']
      const mark2 = ['中国建设银行', '交通银行', '美国银行']
      const mark3 = ['农业银行']
      const mark4 = ['汇丰银行']
      const mark5 = ['花旗银行']
      if (mark1.includes(v)) {
        return 'account_mark1'
      }
      if (mark2.includes(v)) {
        return 'account_mark2'
      }
      if (mark3.includes(v)) {
        return 'account_mark3'
      }
      if (mark4.includes(v)) {
        return 'account_mark4'
      }
      if (mark5.includes(v)) {
        return 'account_mark5'
      }
      if (!(mark1.includes(v) && mark2.includes(v) && mark3.includes(v) && mark4.includes(v) && mark5.includes(v))) {
        return 'account_mark1'
      }
    },
    async bankSlip(row) {
      const res = await axios({
        url: `/backEnd/api/${config.supplyCode}/Defray/getDefrayPaymentBillFile?defrayPaymentId=${row.id}`,
        method: 'get',
        responseType: 'blob'
      })
      const filestream = res.data // 返回的文件流
      const blob = new Blob([filestream], {
        type: 'image/jpeg'
      })
      const url = window.URL.createObjectURL(blob)
      window.open(url)
    },
    billingDetail(row) {
      this.rowData = row
      this.dialogVisible4 = true
    },
    copyWithdetail(row) {
      this.rowData = row
      this.dialogVisible5 = true
    },
    add(type) {
      this.addType = type
      this.dialogVisible1 = true
    }
  }
}
</script>

<style lang="less" scoped>
.view-contanier {
  width: 100%;
  padding: 0 10px;
  height: 100%;
  background: #fff;
  box-sizing: border-box;
  overflow: auto;
  .header {
    width: 100%;
    height: 50px;
    display: flex;
    align-items: center;
    justify-content: space-between;
    .tabItem {
      padding: 15px 20px;
      font-size: 14px;
      font-family: Microsoft YaHei-Regular, Microsoft YaHei;
      font-weight: 400;
      color: #999999;
      line-height: 20px;
      cursor: pointer;
      box-sizing: border-box;
    }
    .active {
      color: #2395f3;
    }
  }
  .opt {
    display: flex;
    font-size: 14px;
    font-family: Microsoft YaHei-Regular, Microsoft YaHei;
    font-weight: 400;
    color: #ffffff;
    & div {
      height: 30px;
      box-sizing: border-box;
      margin-right: 5px;
      border-radius: 2px;
      cursor: pointer;
    }
    .payment {
      width: 80px;
      background: #35BA83;
    }
    .merge {
      width: 100px;
      border: 1px solid #35ba83;
      color: #35ba83;
    }
    .add {
      width: 80px;
      background: #2395f3;
      svg {
        font-size: 20px;
      }
    }
    .derive {
      width: 80px;
      border: 1px solid #2395f3;
      color: #2395f3;
    }
  }
  .data {
    width: 100%;
    height: calc(100% - 50px);
    & .fTable {
      border-top: 1px solid #ebeef5;
      box-sizing: border-box;
    }
    .custom-total {
      display: flex;
      justify-content: space-between;
      align-items: center;
      width: 100%;
      height: 50px;
      background: #f7f7f7;
      border: 1px solid #eeeeee;
      border-top: 0;
      padding-left: 17px;
      padding-right: 40px;
      box-sizing: border-box;
      .select {
        font-size: 14px;
        font-family: Microsoft YaHei-Regular, Microsoft YaHei;
        font-weight: 400;
        color: #999999;
      }
      .total {
        margin-left: 16px;
        font-size: 14px;
        font-family: Microsoft YaHei-Bold, Microsoft YaHei;
        color: #333333;
        span {
          color: #fe453d;
          font-weight: bold;
        }
      }
    }
    .type {
      display: inline-block;
      width: auto;
      height: 26px;
      line-height: 26px;
      border-radius: 4px 4px 4px 4px;
      padding: 0 8px;
      box-sizing: border-box;
      font-size: 12px;
      font-family: Microsoft YaHei-Regular, Microsoft YaHei;
      font-weight: 400;
    }
    .type0 {
      color: #2395F3;
      background: rgba(35, 149, 243, .1);
    }
    .type1 {
      color: #9D67FE;
      background: rgba(157, 103, 254, .1);
    }
    .status {
      margin: 0 auto;
      padding: 5px 6px;
      box-sizing: border-box;
      border-radius: 4px 4px 4px 4px;
      font-size: 12px;
      font-weight: 400;
    }
    .status0 {
      color: #FEAA4F;
      background: rgba(254, 170, 79, .1);
    }
    .status1 {
      color: #2395F3;
      background: rgba(35, 149, 243, .1);
    }
    .status2 {
      color: #35BA83;
      background: rgba(53, 186, 131, .1);
    }
    .status5 {
      color: #2395F3;
      background: rgba(35, 149, 243, .1);
    }
    .status10 {
      color: #35BA83;
      background: rgba(53, 186, 131, .1);
    }
    .status15 {
      color: #9D67FE;
      background: rgba(157, 103, 254, .1);
    }
    .status20 {
      color: #FF7444;
      background: rgba(255, 116, 68, .1);
    }
    .status25 {
      color: #999999;
      background: rgba(153, 153, 153, .1);
    }
    .status-1 {
      color: #FE453D;
      background: rgba(254, 69, 61, .1);
    }
    .account_box {
      display: flex;
      align-items: flex-start;
      flex-direction: column;
    }
    .account-b {
      height: 16px;
      font-size: 12px;
      font-family: Microsoft YaHei-Regular, Microsoft YaHei;
      font-weight: 400;
      color: #999999;
      margin-top: 5px;
      line-height: normal;
    }

    .account_mark {
      display: inline-block;
      width: auto;
      max-width: 170px;
      height: 20px;
      border-radius: 2px 2px 2px 2px;
      padding: 2px 6px;
      box-sizing: border-box;
      margin-right: 5px;
      font-size: 12px;
      font-family: Microsoft YaHei-Regular, Microsoft YaHei;
      font-weight: 400;
      line-height: normal;
      overflow: hidden;
      text-overflow: ellipsis;
      white-space: nowrap;
    }
    .account_mark1 {
      background: rgba(254, 69, 61, .1);
      color: #FE453D;
    }
    .account_mark2 {
      background: rgba(35, 149, 243, .1);
      color: #2395F3;
    }
    .account_mark3 {
      background: rgba(53, 186, 131, .1);
      color: #35BA83;
    }
    .account_mark4 {
      background: rgba(255, 116, 68, .1);
      color: #FF7444;
    }
    .account_mark5 {
      background: rgba(157, 103, 254, .1);
      color: #9D67FE;
    }
  }
  .table-opt {
    display: flex;
    justify-content: space-around;
    span {
      font-size: 14px;
      font-family: Microsoft YaHei-Regular, Microsoft YaHei;
      font-weight: 400;
      color: #2395f3;
      cursor: pointer;
      & + span {
        margin-left: 20px;
      }
    }
  }
}
</style>
