<template>
  <Drawer :drawer="drawer" title="明细列表" :size="1080" @close="handleClose">
    <div class="view">
      <BaseTable :height="height" :tableData="tableData" :isPager="false" :loading="loading">
        <template slot="isReview" slot-scope="row">
          <span :style="{color: row.scope.isReview ? '#35BA83' : '#FE453D'}">{{ row.scope.isReview ? '已支付' : '未支付' }}</span>
        </template>
      </BaseTable>
    </div>
  </Drawer>
</template>

<script>
const BaseTable = () => import('@/components/table/BaseTable.vue')
const Drawer = () => import('@/components/drawer/Drawer')
import { getDefrayInvoice } from '@/api/fee'
export default {
  components: { BaseTable, Drawer },
  props: {
    drawer: {
      type: Boolean,
      default: false
    },
    defrayId: {
      type: [String, Number],
      default: ''
    }
  },
  data() {
    return {
      loading: false,
      tableData: {
        fileds: [
          {
            filed: 'invoiceNumber',
            label: '发票单号',
            width: '160px',
            isCopy: true
          },
          {
            filed: 'blNo',
            label: '提单号',
            width: '170px',
            isCopy: true
          },
          {
            filed: 'amount',
            label: '金额',
            width: '120px'
          },
          {
            filed: 'currency',
            label: '币制',
            width: '90px'
          },
          {
            filed: 'createdName',
            label: '创建人',
            width: '100px'
          },
          {
            filed: 'createdOn',
            label: '创建时间',
            width: '150px',
            formatter: (row) =>
              row.createdOn
                ? row.createdOn.slice(0, row.createdOn.length - 3)
                : ''
          },
          {
            filed: 'expectedPaymentDate',
            label: '预计支付日期',
            width: '150px',
            formatter: (row) =>
              row.createdOn
                ? row.createdOn.slice(0, row.createdOn.length - 3)
                : ''
          },
          {
            filed: 'isReview',
            label: '支付状态',
            width: '100px',
            isCustom: true
          }
        ],
        columns: [],
        total: 0
      },
      height: 0
    }
  },
  watch: {
    drawer(val) {
      if (val) {
        this.getLists()
        this.$nextTick(() => {
          this.height = document.querySelector('.view').offsetHeight - 100
        })
      }
    }
  },
  methods: {
    getLists() {
      this.loading = true
      getDefrayInvoice({
        defrayId: this.defrayId
      }).then((res) => {
        if (res.success) {
          this.loading = false
          this.tableData.columns = res.data
        } else {
          this.$message.error(res.errorMessage)
        }
      })
    },
    handleClose() {
      this.$emit('close')
    }
  }
}
</script>

<style lang="less" scoped>
.view {
  width: calc(100% - 40px);
  height: 100%;
  margin: 20px auto 0;
}
</style>
